import { css } from "@emotion/react";
import WebSiteTitle from "components/Typography/WebSiteTitle";
import { BG_COLOR, BTN_COLOR } from "config/Color";
import { HEADER_HEIGHT, HEADER_TITLE } from "config/Header";
import { useNavigate } from "react-router-dom";
import { flexHorizontalCenter } from "style/flex";

export default function Header() {
  const navigater = useNavigate();

  const handleCllick = () => {
    navigater("stock-manage");
  };

  return (
    <div onClick={handleCllick} css={css([wrapperStyle, flexHorizontalCenter])}>
      <WebSiteTitle>{HEADER_TITLE}</WebSiteTitle>
    </div>
  );
}

const wrapperStyle = css`
  height: ${HEADER_HEIGHT}px;
  color: ${BTN_COLOR.FIVE};
  background: ${BG_COLOR.TWO};
  cursor: pointer;
  padding-left: 30px;
  font-size: 1.5rem;
`;
