import { css } from "@emotion/react";
import { FONT_COLOR } from "config/Color";

export default function FieldsetWrapper({ title, children }) {
  return (
    <fieldset css={css(fieldsetStyle)}>
      <legend css={css(legendStyle)}>{title}</legend>
      {children}
    </fieldset>
  );
}

const legendStyle = css`
  color: ${FONT_COLOR.ONE};
  margin-left: 9px;
  padding: 0 4px;
`;

const fieldsetStyle = css`
  width: 200px;
  min-width: 100px;
  min-height: 88px;
  border-radius: 10px;
  border: 1px solid ${FONT_COLOR.ONE};
  margin-inline-start: 2px;
  margin-inline-end: 2px;
  padding-block-start: 0;
  padding-inline-start: 8px;
  padding-inline-end: 8px;
  padding-block-end: 4px;
`;
