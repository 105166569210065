import { css } from "@emotion/react";

export default function Paper({ children, cusStyle }) {
  return <div css={css([wrapperStyle, cusStyle])}>{children}</div>;
}

const wrapperStyle = css`
  padding: 16px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px #00000040;
  overflow: auto;
`;
