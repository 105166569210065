import DateRangeInput from "components/Input/DateRangeInput";
import Paper from "components/Common/Paper";
import Title from "components/Typography/Title";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { flexHorizontalCenter } from "style/flex";
import { css } from "@emotion/react";
import ContentWrpper from "components/Layout/ContentWrpper";
import NumberRangeInput from "components/Input/NumberRangeInput";
import { useEffect, useRef, useState } from "react";
import LabelInput from "components/Input/LabelInput";
import { FONT_COLOR } from "config/Color";
import NavigateBar from "components/Layout/NavigateBar";
import { useDispatch } from "react-redux";
import { setErrorToast } from "feature/redux/slice/toast";
import { format } from "date-fns";
import { getProductPerformanceListApi } from "api/performance";
import { isEmptyText } from "core/validate";
import withProductPerformanceTableContainer from "components/Table/Hoc/withProductPerformanceTableContainer";
import Table from "components/Table/Table";
import SearchButton from "components/Button/SearchButton";
import ResetButton from "components/Button/ResetButton";

const FunctionTable = withProductPerformanceTableContainer(Table);

// 商品表現
export default function ProductPerformance() {
  const rangeRef = useRef(null);
  const dispatch = useDispatch();
  const [payload, setPayload] = useState({
    startDate: format(new Date(), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
  });
  const [searchQs, setSearchQs] = useState({});
  const [curPerPage, setCurPerPage] = useState(25);
  const [curPage, setCurPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [totalCounts, setTotalCounts] = useState(0);
  const [tableData, setTableData] = useState([]);

  const hasSearchName = Boolean(searchQs?.name);
  const hasSearchCount =
    searchQs.hasOwnProperty("maxCount") || searchQs.hasOwnProperty("minCount");
  const hasSearchRange =
    Boolean(searchQs?.startDate) && Boolean(searchQs?.endDate);

  const handleReset = async () => {
    setCurPage(1);
    setCurPerPage(25);
    setPayload({});
    setSearchQs({});

    if (Boolean(rangeRef?.current?.onReset)) rangeRef?.current?.onReset();

    await getProductPerformanceList({
      page: 1,
      perPage: 25,
    });
  };

  const handleSearch = async ({
    page = curPage,
    perPage = curPerPage,
    useLastPayload,
  }) => {
    setCurPage(page);
    setCurPerPage(perPage);
    let payloadObj = useLastPayload ? searchQs : {};

    if (!useLastPayload) {
      const minCountNum = parseFloat(payload?.minCount);
      const maxCountNum = parseFloat(payload?.maxCount);
      const minCount = minCountNum > maxCountNum ? maxCountNum : minCountNum;
      const maxCount = minCountNum > maxCountNum ? minCountNum : maxCountNum;

      if (minCount > 0) payloadObj.minCount = minCount;
      if (maxCount > 0) payloadObj.maxCount = maxCount;
      if (
        rangeRef?.current?.rangeValue?.startDate &&
        rangeRef?.current?.rangeValue?.endDate
      ) {
        const startDate = format(
          rangeRef?.current?.rangeValue?.startDate,
          "yyyy-MM-dd"
        );
        const endDate = format(
          rangeRef?.current?.rangeValue?.endDate,
          "yyyy-MM-dd"
        );
        payloadObj.startDate = startDate;
        payloadObj.endDate = endDate;
      }

      if (!isEmptyText(payload?.name)) payloadObj.name = payload.name;
    }

    await getProductPerformanceList({
      page,
      perPage,
      payload: payloadObj,
    });
  };

  const handleSetPayload = (key, value) => {
    setPayload((c) => ({ ...c, [key]: value }));
  };

  const handlePerPageChange = async (v) => {
    handleSearch({
      page: 1,
      perPage: v,
      useLastPayload: true,
    });
  };

  const handlePageChange = async (v) => {
    setCurPage(v);
    handleSearch({
      page: v,
      useLastPayload: true,
    });
  };

  const getProductPerformanceList = async ({
    payload = {},
    page = curPage,
    perPage = curPerPage,
  } = {}) => {
    try {
      // TODO：串接 取得商品表現列表 Api
      const {
        totalCounts,
        totalPage,
        data = [],
      } = await getProductPerformanceListApi({
        payload,
        page,
        perPage,
      });
      setTotalPage(totalPage);
      setTotalCounts(totalCounts);
      setTableData(data);
      setSearchQs(payload);
    } catch (error) {
      console.log(error);
      dispatch(setErrorToast("取得資料失敗"));
    }
  };

  useEffect(() => {
    getProductPerformanceList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container>
      <Grid xs={12} sx={{ padding: "0" }}>
        <NavigateBar />
      </Grid>
      <ContentWrpper>
        <Grid xs={12}>
          <Paper>
            <Grid container spacing={1}>
              <Grid
                xs={12}
                css={css([flexHorizontalCenter, inputWrapperStyle])}
              >
                <LabelInput
                  label={"商品搜尋"}
                  onChange={(val) => handleSetPayload("name", val)}
                  value={payload?.name}
                  placeholder={"輸入商品名稱、規格來搜尋"}
                  maxLength={200}
                />
              </Grid>
              <Grid
                xs={12}
                css={css([flexHorizontalCenter, inputWrapperStyle])}
              >
                <NumberRangeInput
                  label={"庫存數量"}
                  minCount={payload?.minCount}
                  setMinCount={(val) => handleSetPayload("minCount", val)}
                  maxCount={payload?.maxCount}
                  setMaxCount={(val) => handleSetPayload("maxCount", val)}
                />
              </Grid>
              <Grid
                xs={12}
                css={css([flexHorizontalCenter, inputWrapperStyle])}
              >
                <Title cusStyle={titleStyle}>資料期間</Title>
                <DateRangeInput
                  type="number"
                  placeholder={"請輸入"}
                  ref={rangeRef}
                />
              </Grid>
              <Grid
                xs={12}
                css={css([flexHorizontalCenter, actionWrapperStyle])}
              >
                <ResetButton onClick={handleReset} />
                <SearchButton
                  onClick={() => {
                    handleSearch({
                      page: 1,
                    });
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid xs={12} sx={{ paddingTop: "30px" }}>
          <Paper cusStyle={searchPaperStyle}>
            <div css={css([flexHorizontalCenter, searchHintStyle])}>
              <Title>搜尋商品總數：{totalCounts}</Title>
              <Title cusStyle={searchFilterStyle}>
                [搜尋條件]
                {Object.keys(searchQs).length === 0
                  ? "無"
                  : `${
                      hasSearchRange
                        ? ` 資料期間：${searchQs?.startDate} ~ ${searchQs?.endDate}`
                        : ""
                    }               
                    ${
                      hasSearchCount
                        ? `${hasSearchRange ? "|" : ""} 庫存數量：${
                            searchQs.hasOwnProperty("maxCount") &&
                            searchQs.hasOwnProperty("minCount")
                              ? `${searchQs.minCount} - ${searchQs.maxCount}`
                              : `${
                                  searchQs.hasOwnProperty("maxCount")
                                    ? `庫存數量小於等於 ${searchQs.maxCount}`
                                    : `庫存數量大於等於 ${searchQs.minCount}`
                                }`
                          }`
                        : ``
                    }
                     `}
                {hasSearchName &&
                  `${hasSearchRange || hasSearchCount ? " |" : ""} 商品搜尋：${
                    searchQs?.name
                  }`}
              </Title>
            </div>
            <FunctionTable
              tableData={tableData}
              totalPage={totalPage}
              perPage={curPerPage}
              page={curPage}
              onPerPageChange={handlePerPageChange}
              onPageChange={handlePageChange}
            />
          </Paper>
        </Grid>
      </ContentWrpper>
    </Grid>
  );
}

const titleStyle = css`
  min-width: 58px;
  padding-right: 16px;
`;

const actionWrapperStyle = css`
  padding-top: 1rem;

  button {
    margin-right: 16px;
  }
`;

const searchFilterStyle = css`
  color: ${FONT_COLOR.ONE};
  padding-left: 24px;
`;

const searchPaperStyle = css`
  padding: 21px 16px;
`;

const inputWrapperStyle = css`
  div.label-input-wrapper {
    width: 100%;
    min-width: 300px;
    height: 40px;
  }

  div.date-range-input-wrapper {
    width: 100%;
    min-width: 280px;
    height: 40px;
  }

  div.min-number-input-wrapper,
  div.max-number-input-wrapper {
    width: 100%;
    min-width: 135px;
    height: 40px;
  }
`;

const searchHintStyle = css`
  padding-bottom: 10px;
`;
