import { css } from "@emotion/react";
import { useMemo } from "react";
import TdComponent from "components/Table/TdComponent";
import TableInput from "components/Input/TableInput";

// 新建商品＆編輯商品 Table
export default function withEditTableContainer(WrapperComponent) {
  return ({ tableData = [], setTableData = () => {} }) => {
    const everyColumnRowlength = useMemo(() => {
      const mergeColumnArray = tableData.map((record) => record["optionOne"]);
      return Math.floor(
        tableData.length / [...new Set(mergeColumnArray)].length
      );
    }, [tableData]);

    const isHasMergeColumn = useMemo(
      () =>
        Boolean(tableData[0]?.hasOwnProperty("specifyTwoId")) &&
        [...new Set(tableData.map(({ optionTwo }) => optionTwo))].length > 1,
      [tableData]
    );

    const handleInputValueChange = (record, key, value) => {
      if (!!isNaN(value)) return;
      const finalValue =
        value.trim() === "" ? "0" : parseFloat(value).toString();

      setTableData((c) => {
        const {
          specifyOneId: targetSpecifyOneId,
          specifyTwoId: targetSpecifyTwoId,
          optionOneId: targetOptionOneId,
          optionTwoId: targetOptionTwoId,
        } = record;

        const newValue = c.map(
          ({
            specifyOneId,
            specifyTwoId,
            optionOneId,
            optionTwoId,
            ...rest
          }) => ({
            ...rest,
            ...(Boolean(specifyOneId) ? { specifyOneId } : {}),
            ...(Boolean(specifyTwoId) ? { specifyTwoId } : {}),
            ...(Boolean(optionOneId) ? { optionOneId } : {}),
            ...(Boolean(specifyTwoId) ? { optionTwoId } : {}),
            ...(specifyOneId === targetSpecifyOneId &&
            specifyTwoId === targetSpecifyTwoId &&
            optionOneId === targetOptionOneId &&
            optionTwoId === targetOptionTwoId
              ? { [key]: finalValue }
              : {}),
          })
        );

        return newValue;
      });
    };

    const headerConfig = useMemo(
      () => [
        {
          key: "order",
          label: "項",
          cusStyle: css`
            min-width: 50px;
          `,
        },
        ...(Boolean(tableData[0]?.hasOwnProperty("specifyOneName"))
          ? [
              {
                key: "specifyOne",
                label: tableData[0].specifyOneName,
                cusStyle: css`
                  min-width: 300px;
                `,
              },
            ]
          : []),
        ...(Boolean(tableData[0]?.hasOwnProperty("specifyTwoName"))
          ? [
              {
                key: "spcifyTwo",
                label: tableData[0].specifyTwoName,
                cusStyle: css`
                  min-width: 300px;
                `,
              },
            ]
          : []),
        {
          key: "purchasePrice",
          label: "進貨價格",
          cusStyle: css`
            min-width: 130px;
          `,
        },
        {
          key: "salePrice",
          label: "售價",
          cusStyle: css`
            min-width: 130px;
          `,
        },
        {
          key: "quantity",
          label: "商品數量",
          cusStyle: css`
            min-width: 130px;
          `,
        },
      ],
      [tableData]
    );

    const bodyConfig = [
      {
        bodyComp: (_, { tdIndex, rowIndex }) => (
          <td key={`td-${tdIndex}`} style={{ textAlign: "center" }}>
            {rowIndex + 1}
          </td>
        ),
      },
      {
        bodyComp: (record, { tdIndex, rowIndex }) => {
          // Merge Column 只需顯示最上方欄位並設定 rowSpan
          const curRowInedx = rowIndex + 1;
          const isFirstRow = curRowInedx % everyColumnRowlength === 1;
          return isHasMergeColumn && isFirstRow ? (
            <TdComponent
              key={`td-${rowIndex}-${tdIndex}`}
              rowSpan={everyColumnRowlength}
            >
              {record.optionOne}
            </TdComponent>
          ) : !isHasMergeColumn && record.optionOne !== undefined ? (
            <TdComponent key={`td-${rowIndex}-${tdIndex}`}>
              {record.optionOne}
            </TdComponent>
          ) : null;
        },
      },
      {
        bodyComp: (record, { tdIndex, rowIndex }) =>
          record?.hasOwnProperty("optionTwo") ? (
            <TdComponent key={`td-${rowIndex}-${tdIndex}`}>
              {record.optionTwo}
            </TdComponent>
          ) : null,
      },
      {
        bodyComp: (record, { rowIndex, tdIndex }) => (
          <TableInput
            key={`td-${rowIndex}-${tdIndex}`}
            value={record.purchasePrice}
            onChange={(value) => {
              handleInputValueChange(record, "purchasePrice", value);
            }}
          />
        ),
      },
      {
        bodyComp: (record, { rowIndex, tdIndex }) => (
          <TableInput
            key={`td-${rowIndex}-${tdIndex}`}
            value={record.salePrice}
            onChange={(value) => {
              handleInputValueChange(record, "salePrice", value);
            }}
          />
        ),
      },
      {
        bodyComp: (record, { rowIndex, tdIndex }) => (
          <TableInput
            key={`td-${rowIndex}-${tdIndex}`}
            value={record.quantity}
            onChange={(value) => {
              handleInputValueChange(record, "quantity", value);
            }}
          />
        ),
      },
    ];

    return (
      <WrapperComponent
        headerConfig={headerConfig}
        bodyConfig={bodyConfig}
        data={tableData}
      />
    );
  };
}
