import { css } from "@emotion/react";
import { Typography } from "@mui/material";

// 選單標題
export default function SideBarTitle({
  children,
  cusStyle,
  onClick = () => {},
}) {
  return (
    <Typography
      css={css([basicStyle, underLineHoverStyle, cusStyle])}
      onClick={onClick}
    >
      {children}
    </Typography>
  );
}

const basicStyle = css`
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.09px;
  text-align: center;
`;

const underLineHoverStyle = css`
  width: 100%;
  background-image: linear-gradient(transparent calc(100% - 1.5px), #fff 2px);
  background-repeat: no-repeat;
  background-size: 0% 100%;
  transition: background-size 0.5s;
  &:hover {
    background-size: 100% 100%;
  }
`;
