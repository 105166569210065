import { css } from "@emotion/react";
import { Dialog } from "@mui/material";
import CancelButton from "components/Button/CancelButton";
import ConfirmButton from "components/Button/ConfirmButton";
import BigTitle from "components/Typography/BigTitle";
import { FONT_COLOR } from "config/Color";
import { flexCenter, flexStart } from "style/flex";

export default function RecoveryStockRecordDialog({
  isOpen = true,
  data = {},
  onConfirm = () => {},
  onCancel = () => {},
}) {
  const { productName, specifyName, stockQuantity, date, currentStock } = data;

  return (
    <Dialog css={css(custStyle)} open={isOpen}>
      <div css={css([flexCenter, titleWrapperStyle])}>
        <BigTitle>復原提醒</BigTitle>
      </div>
      <div css={css([flexStart, contentWrapperstyle])}>
        <BigTitle>
          商品名稱：{productName}
          <br />
          商品規格：
          {Boolean(specifyName) ? specifyName : "單一規格"}
          <p></p>
          現有庫存：{currentStock}
          <br />
          復原至 {date} 庫存數量：{Number(stockQuantity).toLocaleString()}
        </BigTitle>
      </div>
      <div css={css([flexCenter, actionWrapperStyle])}>
        <CancelButton variant="contianed" onClick={onCancel} />
        <ConfirmButton onClick={() => onConfirm(data)} />
      </div>
    </Dialog>
  );
}

const actionWrapperStyle = css`
  button {
    margin: 0 5px;
  }
`;

const custStyle = css`
  .MuiPaper-root {
    width: 400px;
    padding: 16px 24px;
  }
`;

const titleWrapperStyle = css`
  border-bottom: 1px solid ${FONT_COLOR.ONE};
  padding: 8px 0;
`;

const contentWrapperstyle = css`
  padding: 24px 14px;
`;
