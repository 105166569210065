import { css } from "@emotion/react";
import { Typography } from "@mui/material";

// 標題
export default function Title({ children, cusStyle }) {
  return <Typography css={css([basicStyle, cusStyle])}>{children}</Typography>;
}

const basicStyle = css`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  text-align: center;
`;
