import { css } from "@emotion/react";
import TableContentText from "components/Typography/TableContentText";

export default function TdComponent({
  children,
  rowSpan,
  align = "left",
  isAction,
  cusStyle,
  tdStyle,
}) {
  return (
    <td rowSpan={rowSpan} css={css(tdStyle)}>
      <div css={css([basicStyle(align), cusStyle])}>
        {isAction ? children : <TableContentText>{children}</TableContentText>}
      </div>
    </td>
  );
}

const basicStyle = (align) => css`
  margin: 8px;
  display: flex;
  align-items: center;
  justify-content: ${align};
`;
