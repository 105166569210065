import { createSlice } from "@reduxjs/toolkit";

export const toastSlice = createSlice({
  name: "toast",
  initialState: {
    message: "",
    variant: "default",
  },
  reducers: {
    setToast: (state, action) => {
      state.message = action.payload.message;
      state.variant = action.payload.variant;
    },
    setErrorToast: (state, action) => {
      state.message = action.payload;
      state.variant = "error";
    },
    setSuccessToast: (state, action) => {
      state.message = action.payload;
      state.variant = "success";
    },
    setWarningToast: (state, action) => {
      state.message = action.payload;
      state.variant = "warning";
    },
    setInfoToast: (state, action) => {
      state.message = action.payload;
      state.variant = "info";
    },
    setResetToast: (state) => {
      state.message = "";
      state.variant = "default";
    },
  },
});

// Action creators are generated for each case reducer function
export const { setToast, setErrorToast, setSuccessToast, setResetToast } =
  toastSlice.actions;

export default toastSlice.reducer;
