import { css } from "@emotion/react";

export default function ContentWrpper({ children }) {
  return <div css={css(contentWrapperStyle)}>{children}</div>;
}

const contentWrapperStyle = css`
  width: 100%;
  overflow: auto;
  height: calc(100vh - 160px);
`;
