import { Pagination as MuiPagination } from "@mui/material";
import { css } from "@emotion/react";
import PaginationItem from "@mui/material/PaginationItem";
import { BG_COLOR, BTN_COLOR } from "config/Color";
import ButtonText from "components/Typography/ButtonText";
import Selector from "./Selector";

const PER_PAGE_LIST = [25, 50, 100].map((k) => ({
  label: `${k}/每頁`,
  value: k,
}));

export default function Pagination({
  totalPage = 1,
  minWidth = 1430,
  perPage,
  page,
  onPerPageChange = () => {},
  onPageChange = () => {},
}) {
  return (
    <div css={css(wrapperStyle(minWidth))}>
      <MuiPagination
        css={css(basicStyle)}
        count={totalPage}
        page={page}
        variant="outlined"
        shape="rounded"
        onChange={(_, v) => onPageChange(v)}
        renderItem={(item) => (
          <PaginationItem
            slots={{
              previous: () => (
                <ButtonText cusStyle={textStyle}>上一頁</ButtonText>
              ),
              next: () => <ButtonText cusStyle={textStyle}>下一頁</ButtonText>,
            }}
            {...item}
          />
        )}
      ></MuiPagination>
      <Selector
        list={PER_PAGE_LIST}
        value={perPage}
        onChange={onPerPageChange}
      />
    </div>
  );
}

const wrapperStyle = (minWidth) => css`
  width: 100%;
  min-width: ${minWidth}px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 10px;
  height: 45px;
`;

const basicStyle = css`
  button.Mui-selected {
    background-color: ${BG_COLOR.TWO} !important;
    color: ${BTN_COLOR.FIVE} !important;
  }

  button {
    margin: 0;
    padding: 0;
    height: 35px;
    min-width: 40px;
    border-radius: unset;
  }

  li:first-of-type button,
  li:last-child button {
    border-radius: 4px;
  }

  li,
  ul {
    height: 35px;
  }

  li > div {
    display: flex;
    align-items: center;
    height: 100%;
  }
`;

const textStyle = css`
  padding: 14.5px 11.5px;
`;
