import BasicButton from "./BasicButton";
import DoneIcon from '@mui/icons-material/Done';

export default function ConfirmButton({
  onClick = () => {},
  variant = "outlined",
  label = "確認",
}) {
  return (
    <BasicButton
      onClick={onClick}
      startIcon={<DoneIcon />}
      variant={variant}
      label={label}
    />
  );
}
