import { IS_LOCAL } from "core/env";
import { fetches, generateFetchOpts } from "./util";
import { MOCK_DATA } from "./mock";
import queryString from "query-string";

// 商品表現 - 取得列表
export const getProductPerformanceListApi = async ({
  page,
  perPage,
  payload,
}) => {
  const qs = queryString.stringify({ ...payload, page, perPage });
  console.log("商品表現 - 取得列表 QueryString:", qs);
  console.log("商品表現 - 取得列表資料格式:", MOCK_DATA.PERFORMANCE_LIST);

  if (IS_LOCAL) return MOCK_DATA.PERFORMANCE_LIST;
  return fetches(`?${qs}`, generateFetchOpts());
};
