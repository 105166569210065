export const BLACK_COLOR = "#000";
export const WHITE_COLOR = "#fff";

export const BG_COLOR = {
  ONE: "#212529",
  TWO: "#343A40",
  THREE: "#F2F2F2",
};

export const BTN_COLOR = {
  ONE: "#5B86F4",
  TWO: "#2D45C1",
  THREE: "#FF5D5D",
  FOUR: "#F92222",
  FIVE: "#fff",
};

export const FONT_COLOR = {
  ONE: "#8F9093",
};
