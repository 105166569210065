import React from "react";
import { SnackbarProvider, useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setResetToast } from "feature/redux/slice/toast";

export default function ProviderToast({ children }) {
  return (
    <SnackbarProvider maxSnack={3}>
      <ToastTrigger />
      {children}
    </SnackbarProvider>
  );
}

function ToastTrigger() {
  const { enqueueSnackbar } = useSnackbar();
  const disptach = useDispatch();
  const { message, variant } = useSelector((s) => s.toast);

  useEffect(() => {
    if (message) {
      enqueueSnackbar(message, { variant });
      disptach(setResetToast());
    }
  }, [disptach, enqueueSnackbar, message, variant]);

  return <></>;
}
