import { useEffect, useState } from "react";

import {
  OPTIONS,
  OPTION_ONE,
  OPTION_ONE_ID,
  OPTION_TWO,
  OPTION_TWO_ID,
  PURCHASE_PRICE,
  QUANTITY,
  SALE_PRICE,
  SPECIFY_NAME,
  SPECIFY_ONE,
  SPECIFY_ONE_ID,
  SPECIFY_TWO,
  SPECIFY_TWO_ID,
  SPECIFY_UUID,
} from "config/Key";

export default function useGenerateTableData(specifyData) {
  const [tableData, setTableData] = useState([
    {
      [PURCHASE_PRICE]: 0,
      [SALE_PRICE]: 0,
      [QUANTITY]: 0,
    },
  ]);

  useEffect(() => {
    setTableData((c) => {
      const specifyCounts = specifyData.length;

      if (specifyCounts === 0) {
        return [
          {
            [PURCHASE_PRICE]: c[0][PURCHASE_PRICE],
            [SALE_PRICE]: c[0][SALE_PRICE],
            [QUANTITY]: c[0][QUANTITY],
          },
        ];
      } else if (specifyCounts === 1) {
        const data = [];
        const {
          [SPECIFY_NAME]: specifyName,
          [SPECIFY_NAME]: specifyUuid,
          [OPTIONS]: options = [],
        } = specifyData[0];

        if (options.length === 1) {
          return [
            {
              [SPECIFY_ONE_ID]: specifyUuid,
              [SPECIFY_ONE]: specifyName,
              [OPTION_ONE]: options[0].name,
              [OPTION_ONE_ID]: options[0].id,
              [PURCHASE_PRICE]: c[0][PURCHASE_PRICE],
              [SALE_PRICE]: c[0][SALE_PRICE],
              [QUANTITY]: c[0][QUANTITY],
            },
          ];
        } else {
          options.forEach(({ name, id }) => {
            const prev = c.filter(
              ({ [OPTION_ONE_ID]: optionOneId }) => optionOneId === id
            );

            data.push({
              [SPECIFY_ONE_ID]: specifyUuid,
              [SPECIFY_ONE]: specifyName,
              [OPTION_ONE]: name,
              [OPTION_ONE_ID]: id,
              [PURCHASE_PRICE]: prev.length === 1 ? prev[0][PURCHASE_PRICE] : 0,
              [SALE_PRICE]: prev.length === 1 ? prev[0][SALE_PRICE] : 0,
              [QUANTITY]: prev.length === 1 ? prev[0][QUANTITY] : 0,
            });
          });

          return data;
        }
      } else {
        const data = [];

        const {
          [SPECIFY_NAME]: specifyOneName,
          [SPECIFY_UUID]: specifyOneId,
          [OPTIONS]: specifyOneOptions = [],
        } = specifyData[0];
        const {
          [SPECIFY_NAME]: specifyTwoName,
          [SPECIFY_UUID]: specifyTwoId,
          [OPTIONS]: specifyTwoOptions = [],
        } = specifyData[1];

        specifyOneOptions.forEach(({ name: optionOne, id: optionOneId }) => {
          specifyTwoOptions.forEach(({ name: optionTwo, id: optionTwoId }) => {
            const prev = c.filter(
              ({
                [OPTION_ONE_ID]: prevOptionOneId,
                [OPTION_TWO_ID]: prevOptionTwoId,
              }) =>
                prevOptionOneId === optionOneId &&
                prevOptionTwoId === optionTwoId
            );

            data.push({
              [SPECIFY_ONE_ID]: specifyOneId,
              [SPECIFY_ONE]: specifyOneName,
              [OPTION_ONE]: optionOne,
              [OPTION_ONE_ID]: optionOneId,
              [SPECIFY_TWO_ID]: specifyTwoId,
              [SPECIFY_TWO]: specifyTwoName,
              [OPTION_TWO]: optionTwo,
              [OPTION_TWO_ID]: optionTwoId,
              [PURCHASE_PRICE]: prev.length === 1 ? prev[0][PURCHASE_PRICE] : 0,
              [SALE_PRICE]: prev.length === 1 ? prev[0][SALE_PRICE] : 0,
              [QUANTITY]: prev.length === 1 ? prev[0][QUANTITY] : 0,
            });
          });
        });
        return data;
      }
    });
  }, [specifyData]);

  return { tableData, setTableData };
}
