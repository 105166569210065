import { ThemeProvider, createTheme } from "@mui/material";
import { BTN_COLOR, FONT_COLOR, BG_COLOR } from "config/Color";

export default function MuiThemeProvider({ children }) {
  const theme = createTheme({
    typography: {
      fontFamily: [
        "Roboto",
        "Segoe UI",
        "Helvetica Neue",
        "Arial",
        "Noto Sans",
        "Liberation Sans",
        "sans-serif",
        "Tahoma",
        "Verdana",
        "Geneva",
        "Lucida Grande",
      ].join(","),
      button: {
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: 1,
        textTransform: "unset",
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          contained: {
            background: BG_COLOR.ONE,
            color: BG_COLOR.THREE,
            "&:hover": {
              background: BTN_COLOR.ONE,
              color: BG_COLOR.THREE,
            },
            "&:active": {
              background: BTN_COLOR.TWO,
              color: BG_COLOR.THREE,
            },
          },
          outlined: {
            background: BTN_COLOR.FIVE,
            color: FONT_COLOR.ONE,
            border: `1px solid ${FONT_COLOR.ONE}`,
            "&:hover": {
              background: BTN_COLOR.ONE,
              color: BG_COLOR.THREE,
              border: `1px solid ${BG_COLOR.THREE}`,
            },
            "&:active": {
              background: BTN_COLOR.TWO,
              color: BG_COLOR.THREE,
              border: `1px solid ${BG_COLOR.THREE}`,
            },
          },
          root: {
            minWidth: "76px",
            height: "36px",
            fontWeight: 200,
            fontSize: "12px",
            borderRadius: "10px",
            lineHeight: 1,
            padding: "0 8px",
            svg: {
              height: "24px",
              width: "24px",
            },
          },
          startIcon: {
            marginLeft: "-2px",
          },
        },
      },
    },
  });

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
