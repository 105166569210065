import { css } from "@emotion/react";
import { Typography } from "@mui/material";

// 大標題
export default function BigTitle({ children, cusStyle }) {
  return <Typography css={css([basicStyle, cusStyle])}>{children}</Typography>;
}

const basicStyle = css`
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.09px;
  text-align: left;
`;
