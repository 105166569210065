export const INVENTORY_TYPE = {
  INVENTORY_CHECK: "0",
  INCOMING_TO_WAREHOUSE: "1",
  RETURN_TO_WAREHOUSE: "2",
  SHIPMENT_AND_WAREHOUSE: "3",
  DEFECTIVE_DELIVERY: "4",
};

export const BUTTON_TEXT = {
  [INVENTORY_TYPE.INVENTORY_CHECK]: "更新",
  [INVENTORY_TYPE.INCOMING_TO_WAREHOUSE]: "更新",
  [INVENTORY_TYPE.RETURN_TO_WAREHOUSE]: "更新",
  [INVENTORY_TYPE.SHIPMENT_AND_WAREHOUSE]: "更新",
  [INVENTORY_TYPE.DEFECTIVE_DELIVERY]: "更新",
};

export const STOCK_HINT = {
  [INVENTORY_TYPE.INVENTORY_CHECK]: "庫存數量",
  [INVENTORY_TYPE.INCOMING_TO_WAREHOUSE]: "進貨數量",
  [INVENTORY_TYPE.RETURN_TO_WAREHOUSE]: "退貨數量",
  [INVENTORY_TYPE.SHIPMENT_AND_WAREHOUSE]: "出貨數量",
  [INVENTORY_TYPE.DEFECTIVE_DELIVERY]: "瑕疵數量",
};

export const STCOK_OPERATION_TYPES = {
  [INVENTORY_TYPE.INVENTORY_CHECK]: "庫存盤點",
  [INVENTORY_TYPE.INCOMING_TO_WAREHOUSE]: "進貨入庫",
  [INVENTORY_TYPE.RETURN_TO_WAREHOUSE]: "退貨入庫",
  [INVENTORY_TYPE.SHIPMENT_AND_WAREHOUSE]: "出貨出庫",
  [INVENTORY_TYPE.DEFECTIVE_DELIVERY]: "瑕疵出庫",
};

export const STOCK_OPERATION_RADIO_CONFIG = [
  {
    title: "盤點",
    list: [
      {
        label: STCOK_OPERATION_TYPES[INVENTORY_TYPE.INVENTORY_CHECK],
        value: INVENTORY_TYPE.INVENTORY_CHECK,
      },
    ],
  },
  {
    title: "入庫",
    list: [
      {
        label: STCOK_OPERATION_TYPES[INVENTORY_TYPE.INCOMING_TO_WAREHOUSE],
        value: INVENTORY_TYPE.INCOMING_TO_WAREHOUSE,
      },
      {
        label: STCOK_OPERATION_TYPES[INVENTORY_TYPE.RETURN_TO_WAREHOUSE],
        value: INVENTORY_TYPE.RETURN_TO_WAREHOUSE,
      },
    ],
  },
  {
    title: "出庫",
    list: [
      {
        label: STCOK_OPERATION_TYPES[INVENTORY_TYPE.SHIPMENT_AND_WAREHOUSE],
        value: INVENTORY_TYPE.SHIPMENT_AND_WAREHOUSE,
      },
      {
        label: STCOK_OPERATION_TYPES[INVENTORY_TYPE.DEFECTIVE_DELIVERY],
        value: INVENTORY_TYPE.DEFECTIVE_DELIVERY,
      },
    ],
  },
];
