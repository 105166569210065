import BasicButton from "./BasicButton";
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';

export default function ResetButton({
  onClick = () => {},
  variant = "outlined",
  label = "重設",
}) {
  return (
    <BasicButton
      onClick={onClick}
      startIcon={<RefreshOutlinedIcon />}
      variant={variant}
      label={label}
    />
  );
}
