import { css } from "@emotion/react";

export const flexCenter = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const flexHorizontalCenter = css`
  display: flex;
  align-items: center;
`;

export const flexVerticalCenter = css`
  display: flex;
  justify-content: center;
`;

export const flexHorizontalSpaceBetween = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const flexHorizontalSpaceAround = css`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const flexHorizontalEnd = css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const flexStart = css`
  display: flex;
  align-items: start;
  justify-content: start;
`;
