import { css } from "@emotion/react";
import { Typography } from "@mui/material";

// styleName: 表格標題;
export default function TableTitleText({ children, cusStyle }) {
  return (
    <Typography
      css={css([basicStyle, ...(Boolean(cusStyle) ? [cusStyle] : [])])}
    >
      {children}
    </Typography>
  );
}

const basicStyle = css`
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 18.2px;
  text-align: center;
`;
