import { css } from "@emotion/react";
import { SIDEBAR_LIST } from "config/Sidebar";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { flexHorizontalCenter } from "style/flex";
import BigTitle from "../Typography/BigTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import Title from "../Typography/Title";

export default function Breadcrumb({ specifyRoute = [] }) {
  const { pathname } = useLocation();
  const [route, setRoute] = useState([]);
  const isSmall = useMediaQuery("(max-width:420px)");

  useEffect(() => {
    if (specifyRoute.length > 0) setRoute(specifyRoute);
    else {
      SIDEBAR_LIST.forEach(({ label, link, extend = [] }) => {
        if (link === pathname) setRoute([label]);

        extend.forEach(({ label: extendLabel, link: extendLink }) => {
          if (extendLink === pathname) setRoute([label, extendLabel]);
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {route.length > 0 && (
        <div css={css([wrapperStyle, flexHorizontalCenter])}>
          {route.map((path, index) => (
            <div key={path} css={css(flexHorizontalCenter)}>
              {isSmall ? <Title>{path}</Title> : <BigTitle>{path}</BigTitle>}

              {route.length !== 1 &&
                index % 2 === 0 &&
                (isSmall ? (
                  <Title cusStyle={linkStyle}>{">"}</Title>
                ) : (
                  <BigTitle cusStyle={linkStyle}>{">"}</BigTitle>
                ))}
            </div>
          ))}
        </div>
      )}
    </>
  );
}

const linkStyle = css`
  margin: 0 0.5rem;
`;

const wrapperStyle = css`
  height: 100%;
  min-width: 180px;
`;
