import { css } from "@emotion/react";
import { HEADER_HEIGHT } from "config/Header";
import { SIDEBAR_WIDTH } from "config/Sidebar";
import SideBarToggle from "components/Layout/SideBarToggle";
import { BG_COLOR, FONT_COLOR, WHITE_COLOR } from "config/Color";
import SideBarList from "./SideBarList";
import HintText from "components/Typography/HintText";

export default function SideBar({ toggleSidebar, isOpen }) {
  return (
    <div css={css([sidebarStyle(isOpen)])}>
      <SideBarList />
      <HintText cusStyle={copyRightStyle}>2024 © Zh.Yu 版權所有</HintText>
      <SideBarToggle toggleSidebar={toggleSidebar} isOpen={isOpen} />
    </div>
  );
}

const sidebarStyle = (isOpen) => css`
  position: absolute;
  top: ${HEADER_HEIGHT}px;
  left: ${isOpen ? 0 : `${0 - SIDEBAR_WIDTH}px`};
  height: calc(100% - ${HEADER_HEIGHT}px);
  width: ${SIDEBAR_WIDTH}px;
  background-color: ${BG_COLOR.ONE};
  color: ${WHITE_COLOR};
`;

const copyRightStyle = css`
  color: ${FONT_COLOR.ONE};
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 24px;
  font-size: 0.8rem;
`;
