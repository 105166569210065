import Layout from "components/Layout/Layout";
import CreateProduct from "pages/CreateProduct";
import MyProduct from "pages/MyProduct";
import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import ProviderToast from "feature/toast/Toast";
import StockManage from "pages/StockManage";
import ProductPerformance from "pages/ProductPerformance";
import EditProduct from "pages/EditProduct";
import MuiThemeProvider from "provider/MuiThemeProvider";

function App() {
  return (
    <ProviderToast>
      <MuiThemeProvider>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<StockManage />} />
              <Route path="product-manage/my-product" element={<MyProduct />} />
              <Route
                path="product-manage/create-product"
                element={<CreateProduct />}
              />
              <Route
                path="product-manage/:produectId/edit"
                element={<EditProduct />}
              />
              <Route path="stock-manage" element={<StockManage />} />
              <Route
                path="data-center/product-performance"
                element={<ProductPerformance />}
              />
            </Route>
            <Route path="*" element={<div>Not Found</div>} />
          </Routes>
        </Suspense>
      </MuiThemeProvider>
    </ProviderToast>
  );
}

export default App;
