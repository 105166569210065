import { Outlet } from "react-router-dom";
import { useState } from "react";
import SideBar from "components/Layout/SideBar";
import Content from "components/Layout/Content";
import Header from "components/Layout/Header";

export default function Layout() {
  const [isOpen, setOpen] = useState(true);

  return (
    <div>
      <Header />
      <SideBar isOpen={isOpen} toggleSidebar={(v) => setOpen((c) => !c)} />
      <Content isOpen={isOpen}>
        <Outlet />
      </Content>
    </div>
  );
}
