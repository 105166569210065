import { css } from "@emotion/react";
import Paper from "components/Common/Paper";
import { flexHorizontalCenter } from "style/flex";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Title from "components/Typography/Title";
import NavigateBar from "components/Layout/NavigateBar";
import { FONT_COLOR } from "config/Color";
import ContentWrpper from "components/Layout/ContentWrpper";
import DeleteProductDialog from "components/Dialog/DeleteProductDialog";
import { useDispatch } from "react-redux";
import { setErrorToast, setSuccessToast } from "feature/redux/slice/toast";
import NumberRangeInput from "components/Input/NumberRangeInput";
import LabelInput from "components/Input/LabelInput";
import { deleteProductApi, getProductListApi } from "api/myProduct";
import withMyProductTableContainer from "components/Table/Hoc/withMyProductTableContainer";
import Table from "components/Table/Table";
import SearchButton from "components/Button/SearchButton";
import ResetButton from "components/Button/ResetButton";
import AddButton from "components/Button/AddButton";

const DEFAULT_PER_PAGE = 25;

const FunctionTable = withMyProductTableContainer(Table);

// 我的商品
export default function MyProduct() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [productSearch, setProductSearch] = useState("");
  const [minStockCount, setMinStockCount] = useState("");
  const [maxStockCount, setMaxStockCount] = useState("");
  const [isShowDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deletedData, setDeleteData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [totalCounts, setTotalCounts] = useState();
  const [totalPage, setTotalPage] = useState(1);
  const [searchQs, setSearchQs] = useState({});
  const [curPerPage, setCurPerPage] = useState(25);
  const [curPage, setCurPage] = useState(1);

  const handleDeleteButtonClick = (row) => {
    setShowDeleteDialog(true);
    setDeleteData(row);
  };

  const handleEditButtonClick = ({ productId }) => {
    navigate(`/product-manage/${productId}/edit`);
  };

  const handleDeleteDialogCancel = () => {
    setShowDeleteDialog(false);
    setDeleteData({});
  };

  const handleDelete = async (data = {}) => {
    try {
      const {
        productId,
        specifyOneId,
        optionOneId,
        specifyTwoId,
        optionTwoId,
      } = data;
      // TODO : 串接 刪除商品 Api
      await deleteProductApi({
        productId,
        ...(Boolean(specifyOneId) ? { specifyOneId } : {}),
        ...(Boolean(optionOneId) ? { optionOneId } : {}),
        ...(Boolean(specifyTwoId) ? { specifyTwoId } : {}),
        ...(Boolean(optionTwoId) ? { optionTwoId } : {}),
      });
      setShowDeleteDialog(false);
      dispatch(setSuccessToast("刪除成功"));
      await getProductListData({ payload: searchQs });
      setDeleteData({});
    } catch (error) {
      dispatch(setErrorToast("刪除失敗"));
    }
  };

  const handleReset = async () => {
    setProductSearch("");
    setMinStockCount("");
    setMaxStockCount("");
    setCurPage(1);
    setCurPerPage(DEFAULT_PER_PAGE);
    try {
      await getProductListData({
        page: 1,
        perPage: 25,
      });
    } catch (error) {
      console.log("error:", error);
    }
  };

  const handleSearch = async ({
    page = curPage,
    perPage = curPerPage,
    curPayload,
  } = {}) => {
    setCurPage(page);
    setCurPerPage(perPage);
    const minStockCountNum = parseFloat(minStockCount);
    const maxStockCountNum = parseFloat(maxStockCount);

    const minCount =
      minStockCountNum > maxStockCountNum ? maxStockCountNum : minStockCountNum;
    const maxCount =
      minStockCountNum > maxStockCountNum ? minStockCountNum : maxStockCountNum;

    const payload = {
      ...(productSearch.length > 0 ? { name: productSearch } : {}),
      ...(minCount > 0 ? { minCount } : {}),
      ...(maxCount > 0 ? { maxCount } : {}),
    };

    await getProductListData({
      payload: Boolean(curPayload) ? curPayload : payload,
      page,
      perPage,
    });
  };

  const handlePerPageChange = (v) => {
    handleSearch({
      page: 1,
      perPage: v,
      curPayload: searchQs,
    });
  };

  const handlePageChange = (v) => {
    handleSearch({
      page: v,
      curPayload: searchQs,
    });
  };

  const getProductListData = async ({
    payload = {},
    page = curPage,
    perPage = curPerPage,
  } = {}) => {
    try {
      // TODO : 串接 商品列表 Api
      const {
        totalCounts,
        data = [],
        totalPage,
      } = await getProductListApi({
        payload,
        page,
        perPage,
      });
      setTableData(data);
      setTotalCounts(totalCounts);
      setTotalPage(totalPage);
      setSearchQs(payload);
    } catch (error) {
      console.log(error);
      dispatch(setErrorToast("取得商品列表失敗"));
    }
  };

  useEffect(() => {
    getProductListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container>
      <Grid xs={12} sx={{ padding: "0" }}>
        <NavigateBar>
          <AddButton
            variant="contained"
            label={"新增商品"}
            onClick={() => navigate("/product-manage/create-product")}
          />
        </NavigateBar>
      </Grid>
      <ContentWrpper>
        <Grid xs={12}>
          <Paper cusStyle={searchPaperStyle}>
            <Grid container spacing={{ xs: 2 }}>
              <Grid
                xs={12}
                md={6}
                css={css([flexHorizontalCenter, inputWrapperStyle])}
              >
                <LabelInput
                  label={"商品搜尋"}
                  onChange={setProductSearch}
                  value={productSearch}
                  placeholder={"輸入商品名稱、規格來搜尋"}
                  maxLength={100}
                />
              </Grid>
              <Grid
                xs={12}
                md={6}
                css={css([flexHorizontalCenter, inputWrapperStyle])}
              >
                <NumberRangeInput
                  label={"庫存數量"}
                  minCount={minStockCount}
                  setMinCount={setMinStockCount}
                  maxCount={maxStockCount}
                  setMaxCount={setMaxStockCount}
                />
              </Grid>
              <Grid
                xs={12}
                css={css([flexHorizontalCenter, actionWrapperStyle])}
              >
                <ResetButton onClick={handleReset} />
                <SearchButton
                  onClick={() => {
                    handleSearch(1, curPerPage);
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid xs={12} sx={{ paddingTop: "30px" }}>
          <Paper cusStyle={searchPaperStyle}>
            <div css={css([flexHorizontalCenter, titleWrapperStyle])}>
              <Title>搜尋商品總數：{totalCounts}</Title>
              <Title cusStyle={searchFilterStyle}>
                搜尋條件：
                {Object.keys(searchQs).length === 0
                  ? "無"
                  : `${
                      searchQs.hasOwnProperty("name")
                        ? `商品搜尋：${searchQs.name} `
                        : ""
                    }               
                    ${
                      searchQs.hasOwnProperty("maxCount") ||
                      searchQs.hasOwnProperty("minCount")
                        ? `${
                            searchQs.hasOwnProperty("name") ? "|" : ""
                          } 庫存數量：${
                            searchQs.hasOwnProperty("maxCount") &&
                            searchQs.hasOwnProperty("minCount")
                              ? `${searchQs.minCount} - ${searchQs.maxCount}`
                              : `${
                                  searchQs.hasOwnProperty("maxCount")
                                    ? `庫存數量小於等於 ${searchQs.maxCount}`
                                    : `庫存數量大於等於 ${searchQs.minCount}`
                                }`
                          }`
                        : ``
                    }
                     `}
              </Title>
            </div>
            <FunctionTable
              tableData={tableData}
              onDeleteButtonClick={handleDeleteButtonClick}
              onEditButtonClick={handleEditButtonClick}
              totalPage={totalPage}
              onPerPageChange={handlePerPageChange}
              onPageChange={handlePageChange}
              perPage={curPerPage}
              page={curPage}
            />
          </Paper>
        </Grid>
      </ContentWrpper>
      <DeleteProductDialog
        isOpen={isShowDeleteDialog}
        data={deletedData}
        onCancel={handleDeleteDialogCancel}
        onConfirm={handleDelete}
      />
    </Grid>
  );
}

const titleWrapperStyle = css`
  padding-bottom: 10px;
`;

const searchFilterStyle = css`
  color: ${FONT_COLOR.ONE};
  padding-left: 24px;
`;

const actionWrapperStyle = css`
  padding-top: 1rem;

  button {
    margin-right: 16px;
  }
`;

const searchPaperStyle = css`
  padding: 21px 16px;
`;

const inputWrapperStyle = css`
  div.label-input-wrapper {
    width: 100%;
    min-width: 220px;
    height: 40px;
  }

  div.date-range-input-wrapper {
    width: 100%;
    min-width: 280px;
    height: 40px;
  }

  div.min-number-input-wrapper,
  div.max-number-input-wrapper {
    width: 100%;
    min-width: 95px;
    height: 40px;
  }
`;
