import { css } from "@emotion/react";
import Breadcrumb from "components/Common/Breadcrumb";
import { flexHorizontalCenter, flexHorizontalSpaceBetween } from "style/flex";

export default function NavigateBar({
  children,
  specifyRoute = [],
  paddingBottom = 8,
}) {
  return (
    <div css={css([flexHorizontalSpaceBetween, basicStyle(paddingBottom)])}>
      <Breadcrumb specifyRoute={specifyRoute} />
      <div css={css([flexHorizontalCenter, buttonWrapperStyle])}>
        {children}
      </div>
    </div>
  );
}

const basicStyle = (paddingBottom) => css`
  padding-bottom: ${paddingBottom}px;
  min-height: 36px;
  overflow: auto;
`;

const buttonWrapperStyle = css`
  button {
    margin-left: 8px;
  }
`;
