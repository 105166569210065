import BasicButton from "./BasicButton";
import EditIcon from "@mui/icons-material/Edit";

export default function EditButton({
  onClick = () => {},
  variant = "outlined",
  label = "編輯",
}) {
  return (
    <BasicButton
      onClick={onClick}
      startIcon={<EditIcon />}
      variant={variant}
      label={label}
    />
  );
}
