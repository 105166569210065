import BasicButton from "./BasicButton";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';

export default function SaveButton({
  cusStyle,
  onClick = () => {},
  variant = "outlined",
  label = "儲存",
}) {
  return (
    <BasicButton
      onClick={onClick}
      cusStyle={cusStyle}
      startIcon={<SaveOutlinedIcon />}
      variant={variant}
      label={label}
    />
  );
}
