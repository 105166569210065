import { InputAdornment, OutlinedInput } from "@mui/material";
import { css } from "@emotion/react";
import HintText from "components/Typography/HintText";
import { BTN_COLOR } from "config/Color";
import { fullWidthStyle } from "style/common";

export default function FiledInput({
  placeholder,
  fullWidth = true,
  cusStyle,
  outerStyle,
  maxLength,
  inputLength = 0,
  type = "text",
  error = false,
  ...rest
}) {
  return (
    <div
      css={css([Boolean(fullWidth) && fullWidthStyle, outerStyle])}
      className="field-input-wrapper"
    >
      <OutlinedInput
        type={type}
        error={Boolean(error)}
        {...(Boolean(maxLength)
          ? {
              endAdornment: (
                <InputAdornment css={css(adornmentStyle)} position="end">
                  <p>
                    {inputLength}/{maxLength}
                  </p>
                </InputAdornment>
              ),
            }
          : {})}
        inputProps={{
          ...(Boolean(maxLength) ? { maxLength } : {}),
          ...(Boolean(placeholder) ? { placeholder } : {}),
        }}
        css={css([
          basicStyle(type, fullWidth),
          ...(Boolean(cusStyle) ? [cusStyle] : []),
        ])}
        {...rest}
      />

      {Boolean(error) && <HintText cusStyle={errorStyle}>{error}</HintText>}
    </div>
  );
}

const errorStyle = css`
  text-align: left;
  padding-top: 4px;
  color: ${BTN_COLOR.FOUR};
`;

const basicStyle = (type, fullWidth) => css`
  height: 38px;
  padding-right: 8px;
  background: white;
  ${fullWidth && "width:100%;"}
  input {
    padding: 16.5px 8px;
    ${type === "number" && "text-align:end; padding-right:0px;"}
  }

  & input::placeholder {
    font-size: 12px;
  }
`;

const adornmentStyle = css`
  p {
    font-size: 12px;
    margin: unset;
    line-height: 1;
  }
`;
