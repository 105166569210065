import { css } from "@emotion/react";
import { useMemo } from "react";
import TdComponent from "components/Table/TdComponent";
import EditButton from "components/Button/EditButton";
import DeleteButton from "components/Button/DeleteButton";

// 我的商品 Table
export default function withMyProductTableContainer(WrapperComponent) {
  return ({
    tableData = [],
    totalPage,
    perPage,
    page,
    onPerPageChange = () => {},
    onPageChange = () => {},
    onDeleteButtonClick = () => {},
    onEditButtonClick = () => {},
  }) => {
    // 取得不重複的產品名稱
    const productNameArray = [
      ...new Set(tableData.map(({ productName }) => productName)),
    ];

    // 取得每個商品的列述 Ex. { 商品名稱1 : 4, 商品名稱2 : 2 }
    const productNameCounts = useMemo(
      () =>
        tableData
          .map(({ productName }) => productName)
          .reduce((accumulator, currentItem) => {
            // 如果當前商品名稱已經存在於累加器中，則將其數量加1，否則設置數量為1
            accumulator[currentItem] = (accumulator[currentItem] || 0) + 1;
            return accumulator;
          }, {}),
      [tableData]
    );

    const headerConfig = [
      {
        key: "order",
        label: "項",
        cusStyle: css`
          min-width: 50px;
        `,
      },
      {
        key: "productName",
        label: "商品名稱",
        cusStyle: css`
          min-width: 318px;
        `,
      },
      {
        key: "specify",
        label: "商品規格",
        cusStyle: css`
          min-width: 200px;
        `,
      },
      {
        key: "purchasePrice",
        label: "進貨價格",
        cusStyle: css`
          min-width: 100px;
        `,
      },
      {
        key: "salePrice",
        label: "售價",
        cusStyle: css`
          min-width: 100px;
        `,
      },
      {
        key: "stock",
        label: "現有庫存",
        cusStyle: css`
          min-width: 100px;
        `,
      },
      {
        key: "action",
        label: "操作",
        cusStyle: css`
          min-width: 200px;
        `,
      },
    ];

    const bodyConfig = [
      {
        bodyComp: ({ productName }, { rowIndex, tdIndex }) => {
          const prevRecord = rowIndex === 0 ? {} : tableData[rowIndex - 1];
          const mergetCounts = productNameCounts[productName];
          const isFirstRow =
            rowIndex === 0 ? true : prevRecord.productName !== productName;
          const order = productNameArray.indexOf(productName) + 1;

          return mergetCounts > 1 && isFirstRow ? (
            <TdComponent
              key={`td-${rowIndex}-${tdIndex}`}
              rowSpan={mergetCounts}
              align="center"
            >
              {order}
            </TdComponent>
          ) : mergetCounts > 1 && !isFirstRow ? null : (
            <TdComponent key={`td-${rowIndex}-${tdIndex}`} align="center">
              {order}
            </TdComponent>
          );
        },
      },
      {
        bodyComp: ({ productName }, { rowIndex, tdIndex }) => {
          const prevRecord = rowIndex === 0 ? {} : tableData[rowIndex - 1];
          const mergetCounts = productNameCounts[productName];
          const isFirstRow =
            rowIndex === 0 ? true : prevRecord.productName !== productName;

          return mergetCounts > 1 && isFirstRow ? (
            <TdComponent
              key={`td-${rowIndex}-${tdIndex}`}
              rowSpan={mergetCounts}
            >
              {productName}
            </TdComponent>
          ) : mergetCounts > 1 && !isFirstRow ? null : (
            <TdComponent key={`td-${rowIndex}-${tdIndex}`}>
              {productName}
            </TdComponent>
          );
        },
      },
      {
        bodyComp: ({ optionTwo, optionOne }, { rowIndex, tdIndex }) => (
          <TdComponent key={`td-${rowIndex}-${tdIndex}`}>
            {Boolean(optionTwo)
              ? `${optionOne},${optionTwo}`
              : Boolean(optionOne)
              ? optionTwo
              : "單一規格"}
          </TdComponent>
        ),
      },
      {
        bodyComp: ({ purchasePrice }, { rowIndex, tdIndex }) => (
          <TdComponent key={`td-${rowIndex}-${tdIndex}`} align="right">
            $ {Number(purchasePrice).toLocaleString()}
          </TdComponent>
        ),
      },
      {
        bodyComp: ({ salePrice }, { rowIndex, tdIndex }) => (
          <TdComponent key={`td-${rowIndex}-${tdIndex}`} align="right">
            $ {Number(salePrice).toLocaleString()}
          </TdComponent>
        ),
      },
      {
        bodyComp: ({ quantity }, { rowIndex, tdIndex }) => (
          <TdComponent key={`td-${rowIndex}-${tdIndex}`} align="right">
            {Number(quantity).toLocaleString()}
          </TdComponent>
        ),
      },
      {
        bodyComp: (record, { rowIndex, tdIndex }) => (
          <TdComponent
            key={`td-${rowIndex}-${tdIndex}`}
            cusStyle={actionStyle}
            align="center"
            isAction
          >
            <EditButton
              onClick={() => {
                onEditButtonClick(record);
              }}
            />
            <DeleteButton
              label="刪除"
              onClick={() => {
                onDeleteButtonClick(record);
              }}
            />
          </TdComponent>
        ),
      },
    ];

    return (
      <>
        <WrapperComponent
          headerConfig={headerConfig}
          bodyConfig={bodyConfig}
          data={tableData}
          minWidth={1080}
          isShowPagination
          totalPage={totalPage}
          perPage={perPage}
          page={page}
          onPerPageChange={onPerPageChange}
          onPageChange={onPageChange}
        />
      </>
    );
  };
}

const actionStyle = css`
  & button:first-of-type {
    margin-right: 4px;
  }

  & button:last-child {
    margin-left: 4px;
  }
`;
