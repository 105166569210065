import { css } from "@emotion/react";
import { BG_COLOR, FONT_COLOR } from "config/Color";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { flexCenter } from "style/flex";

export default function SideBarToggle({ toggleSidebar, isOpen }) {
  return (
    <div
      onClick={toggleSidebar}
      css={css([sidebarToggleStyle(isOpen), flexCenter])}
    >
      {isOpen ? (
        <KeyboardArrowLeftIcon css={css(iconStyle)} />
      ) : (
        <KeyboardArrowRightIcon css={css(iconStyle)} />
      )}
    </div>
  );
}

const sidebarToggleStyle = (isOpen) => css`
  position: absolute;
  right: ${isOpen ? 0 : -36}px;
  height: 36px;
  bottom: 128px;
  width: 36px;
  z-index: 1;
  background: ${BG_COLOR.TWO};
  
  border-radius: ${isOpen ? "10px 0 0 10px" : "0 10px 10px 0"};
  cursor: pointer;
  
  svg {
    font-size: 36px;
  
`;

const iconStyle = css`
  color: ${FONT_COLOR.ONE};}
`;
