import { Button, Typography } from "@mui/material";
import { css } from "@emotion/react";
import { BTN_COLOR } from "config/Color";

export default function BasicButton({
  startIcon,
  cusStyle,
  onClick = () => {},
  variant = "outlined",
  label,
  mode,
}) {
  const buttonStyle = mode === "error" ? errorStyle : "";

  return (
    <Button
      css={css([buttonStyle, cusStyle])}
      color="primary"
      startIcon={startIcon}
      onClick={onClick}
      variant={variant}
    >
      <Typography variant="button">{label}</Typography>
    </Button>
  );
}

const errorStyle = css`
  &:hover {
    background: ${BTN_COLOR.THREE};
  }

  &:active {
    background: ${BTN_COLOR.FOUR};
  }
`;
