import { css } from "@emotion/react";
import AddButton from "components/Button/AddButton";
import DeleteButton from "components/Button/DeleteButton";
import FiledInput from "components/Input/FieldInput";
import Title from "components/Typography/Title";
import { BG_COLOR, FONT_COLOR } from "config/Color";
import { NUMBER_TO_ZH } from "config/Number";
import { flexHorizontalCenter } from "style/flex";

export default function SpecificationBlock({
  index,
  data = {},
  errorMessage = {},
  onChange = () => {},
  onDeleteSpecification = () => {},
  onAddOption = () => {},
  onDeleteOption = () => {},
}) {
  const { specifyName = "", options = [] } = data;
  const {
    specifyName: errorMessageSpecifyName = "",
    options: errorMessageOptions = [],
  } = errorMessage;
  const isLastOptions = options.length === 1;

  return (
    <div css={css(wrapperStyle)} className="specification-block">
      <div css={css(innerWrappertStyle)}>
        <div className="row" css={css(flexHorizontalCenter, rowStyle)}>
          <Title cusStyle={titleStyle}>規格{NUMBER_TO_ZH[index + 1]}</Title>
          <FiledInput
            onChange={(e) => {
              onChange({
                mainIndex: index,
                value: e?.target?.value,
              });
            }}
            value={specifyName}
            error={errorMessageSpecifyName}
            placeholder={"輸入規格名稱"}
            maxLength={20}
            inputLength={specifyName?.length || 0}
          />
          <DeleteButton
            cusStyle={deleteSpecifybuttonStyle}
            onClick={() => onDeleteSpecification(index)}
            label={"刪除規格"}
          />
        </div>
        <div className="row" css={css(flexHorizontalCenter, rowStyle)}>
          <Title cusStyle={titleStyle}>選項</Title>
          <AddButton label={"新增選項"} onClick={() => onAddOption(index)} />
        </div>
        {options?.length > 0 &&
          options.map(({ name }, i) => (
            <div
              className="row"
              css={css(flexHorizontalCenter, rowStyle)}
              key={i}
            >
              <FiledInput
                onChange={(e) => {
                  onChange({
                    mainIndex: index,
                    optionIndex: i,
                    value: e?.target?.value,
                  });
                }}
                error={errorMessageOptions[i]?.name}
                value={name}
                placeholder={"輸規格選項名稱"}
                maxLength={20}
                inputLength={name?.length || 0}
              />
              {!isLastOptions && (
                <DeleteButton
                  cusStyle={deleteSpecifybuttonStyle}
                  label={"刪除"}
                  onClick={() => onDeleteOption(index, i)}
                />
              )}
            </div>
          ))}
      </div>
    </div>
  );
}

const wrapperStyle = css`
  min-width: 300px;
  flex-grow: 1;
  border-radius: 10px;
  margin: 8px 0;
  background: ${BG_COLOR.THREE};
  border: 1px solid ${FONT_COLOR.ONE};

  & .row:last-child {
    padding: 0 8px;
  }
`;

const titleStyle = css`
  min-width: 45px;
  margin-right: 8px;
  text-align-last: justify;
  width: 100%;
  max-width: max-content;
`;

const deleteSpecifybuttonStyle = css`
  margin-left: 8px;
`;

const innerWrappertStyle = css`
  margin: 16px 0;
`;

const rowStyle = css`
  padding: 0 8px 8px 8px;
`;
