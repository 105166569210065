import BasicButton from "./BasicButton";
import DeleteIcon from "@mui/icons-material/Delete";

export default function DeleteButton({
  cusStyle,
  onClick = () => {},
  variant = "outlined",
  label = "刪除",
}) {
  return (
    <BasicButton
      onClick={onClick}
      cusStyle={cusStyle}
      startIcon={<DeleteIcon />}
      variant={variant}
      label={label}
    />
  );
}
