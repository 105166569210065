import { css } from "@emotion/react";
import { Radio as MuiRadio } from "@mui/material";
import Title from "components/Typography/Title";
import { BG_COLOR, BTN_COLOR, FONT_COLOR } from "config/Color";
import { flexHorizontalCenter } from "style/flex";

export default function Radio({
  label,
  value,
  onChange = () => {},
  activeValue,
}) {
  const isChecked = value === activeValue;

  return (
    <div css={css(flexHorizontalCenter)}>
      <MuiRadio
        value={value}
        onClick={(e) => onChange(e.target.value)}
        css={css(radioStyle)}
        checked={isChecked}
        sx={{
          color: FONT_COLOR.ONE,
          "&.Mui-checked": {
            color: BTN_COLOR.ONE,
          },
        }}
      />
      <Title cusStyle={titleStyle(isChecked)}>{label}</Title>
    </div>
  );
}

const radioStyle = css`
  padding: 0 10xp;
`;

const titleStyle = (isChecked) => css`
  color: ${isChecked ? BTN_COLOR.ONE : BG_COLOR.ONE};
`;
