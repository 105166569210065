import BasicButton from "./BasicButton";
import SearchIcon from "@mui/icons-material/Search";

export default function SearchButton({
  onClick = () => {},
  variant = "outlined",
  label = "搜尋",
}) {
  return (
    <BasicButton
      onClick={onClick}
      startIcon={<SearchIcon />}
      variant={variant}
      label={label}
    />
  );
}
