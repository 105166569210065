import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import { appBaseName } from "core/basename";
import store from "feature/redux/store";
import "./style/index.css";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

const root = createRoot(document.getElementById("root"));
const createHistory = (basename) => createBrowserHistory({ basename });

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter
        history={createHistory(appBaseName)}
        basename={appBaseName}
      >
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
