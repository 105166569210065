import { css } from "@emotion/react";
import { Typography } from "@mui/material";

// styleName: 提示字;
export default function HintText({ children, cusStyle }) {
  return (
    <Typography
      css={css([basicStyle, ...(Boolean(cusStyle) ? [cusStyle] : [])])}
    >
      {children}
    </Typography>
  );
}

const basicStyle = css`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.06px;
  text-align: center;
`;
