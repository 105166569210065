import Radio from "components/Radio/Radio";
import FieldsetWrapper from "../Common/FieldsetWrapper";

export default function FieldsetRadio({
  title,
  config = [],
  onChange = () => {},
  activeValue,
}) {
  return (
    <FieldsetWrapper title={title}>
      {config.map(({ value, label }) => (
        <Radio
          value={value}
          label={label}
          key={value}
          activeValue={activeValue}
          onChange={onChange}
        />
      ))}
    </FieldsetWrapper>
  );
}
