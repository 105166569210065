import { MenuItem, Select } from "@mui/material";
import { css } from "@emotion/react";
import { FONT_COLOR } from "config/Color";

export default function Selector({ value, onChange, list = [] }) {
  return (
    <Select
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      css={css(basicStyle)}
    >
      {list.map(({ label, value }) => (
        <MenuItem value={value} key={value}>
          <div css={css(itemStyle)}>{label}</div>
        </MenuItem>
      ))}
    </Select>
  );
}

const basicStyle = css`
  height: 35px;
  margin-left: 8px;
  border-color: ${FONT_COLOR.ONE};
  width: 115px;
  border-radius: 5px;
`;

const itemStyle = css`
  display: flex;
  height: 23px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
`;
