import Paper from "components/Common/Paper";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { useEffect, useState } from "react";
import NavigateBar from "components/Layout/NavigateBar";
import ProductEditor from "components/Common/ProductEditor";
import { useNavigate, useParams } from "react-router-dom";
import ContentWrpper from "components/Layout/ContentWrpper";
import {
  createAndUpdateProductInfoApi,
  getProductInfoApi,
  getSpecifyProductListApi,
} from "api/editProduct";
import { validateProductSave } from "core/validate";
import { useDispatch } from "react-redux";
import { setErrorToast, setSuccessToast } from "feature/redux/slice/toast";
import withEditTableContainer from "components/Table/Hoc/withEditTableContainer";
import Table from "components/Table/Table";
import useGenerateTableData from "hook/useGenerateTableData";
import CancelButton from "components/Button/CancelButton";
import SaveButton from "components/Button/SaveButton";

const FunctionTable = withEditTableContainer(Table);

// 編輯商品
export default function EditProduct() {
  const navigate = useNavigate();
  const { produectId } = useParams();
  const dispatch = useDispatch();
  const [productName, setProductName] = useState("");
  const [specifyData, setSpecifyData] = useState([]);
  const [errorMessage, setErrorMessage] = useState({});
  const { tableData, setTableData } = useGenerateTableData(specifyData);

  const handleSave = async () => {
    if (validateProductSave({ productName, specifyData }, setErrorMessage)) {
      try {
        // TODO : 新增/編輯商品 - 儲存商品規格
        await createAndUpdateProductInfoApi({
          productName,
          specifyData,
          tableData,
        });
        dispatch(setSuccessToast("儲存成功"));
      } catch (error) {
        dispatch(setErrorToast("儲存失敗"));
      }
    }
  };

  const handleCancel = () => {
    navigate("/product-manage/my-product");
  };

  useEffect(() => {
    const getData = async () => {
      try {
        // TODO：串接 取得指定商品資料 Api
        const [list, info] = await Promise.all([
          getSpecifyProductListApi(produectId),
          getProductInfoApi(produectId),
        ]);
        setSpecifyData(info.specifyData);
        setProductName(info.productName);
        setTableData(list);
      } catch (error) {
        console.log("error:", error);
        dispatch(setErrorToast("取得商品資料失敗"));
      }
    };
    if (produectId) getData();
  }, [dispatch, produectId, setTableData]);

  return (
    <Grid container>
      <Grid xs={12} sx={{ padding: "0" }}>
        <NavigateBar specifyRoute={["商品管理", "編輯商品"]}>
          <CancelButton onClick={handleCancel} />
          <SaveButton variant="contained" onClick={handleSave} />
        </NavigateBar>
      </Grid>
      <ContentWrpper>
        <ProductEditor
          productName={productName}
          setProductName={setProductName}
          specifyData={specifyData}
          setSpecifyData={setSpecifyData}
          errorMessage={errorMessage}
        />
        <Grid xs={12} sx={{ paddingTop: "30px" }}>
          <Paper>
            <FunctionTable tableData={tableData} setTableData={setTableData} />
          </Paper>
        </Grid>
      </ContentWrpper>
    </Grid>
  );
}
