import { css } from "@emotion/react";
import Pagination from "components/Common/Pagination";
import TableTitleText from "components/Typography/TableTitleText";

// 根據某欄位平均合併欄表格 Ex. 編輯商品、新增商品
export default function Table({
  data = [],
  headerConfig = [],
  bodyConfig = [],
  enableHover = true,
  isShowPagination = false,
  minWidth,
  totalPage,
  perPage,
  page,
  onPerPageChange = () => {},
  onPageChange = () => {},
}) {
  console.log("table:", data);

  return (
    <>
      <table css={css(tableStyle)}>
        <thead>
          <tr css={css(headerStyle)}>
            {headerConfig.map(({ key, label, cusStyle }) => (
              <td key={key} css={cusStyle}>
                <TableTitleText>{label}</TableTitleText>
              </td>
            ))}
          </tr>
        </thead>
        <tbody css={css(tbodyStyle(enableHover))}>
          {data.map((record, index) => (
            <tr key={`tr-${index}`}>
              {bodyConfig.map(({ bodyComp = () => {} }, tdIndex) =>
                bodyComp(record, {
                  rowIndex: index,
                  tdIndex,
                })
              )}
            </tr>
          ))}
        </tbody>
      </table>
      {isShowPagination && (
        <Pagination
          minWidth={minWidth}
          totalPage={totalPage}
          perPage={perPage}
          page={page}
          onPerPageChange={onPerPageChange}
          onPageChange={onPageChange}
        />
      )}
    </>
  );
}

const headerStyle = css`
  background: #0000000f;
  height: 38px;
`;

const tableStyle = css`
  overflow: auto;
  td {
    border: 1px solid #b9b9b9;
  }
  border-collapse: separate;
  width: 100%;
  border-spacing: 0;
  border: 1px solid #b9b9b9;
`;

const tbodyStyle = (enableHover) => css`
  td {
    height: 54px;
    padding: 0;
  }
  ${enableHover &&
  `& tr:hover {
    background: #F9F9F9;
  }`}
`;
