import { css } from "@emotion/react";
import { STCOK_OPERATION_TYPES } from "config/StockManage";
import TdComponent from "components/Table/TdComponent";
import RecoveryButton from "components/Button/RecoveryButton";

// 庫存管理 Table
export default function withStockTableContainer(WrapperComponent) {
  return ({ tableData = [], onRecoveryButtonClick = () => {} }) => {
    const headerConfig = [
      {
        key: "date",
        label: "日期",
        cusStyle: css`
          min-width: 150px;
        `,
      },
      {
        key: "productName",
        label: "商品名稱",
        cusStyle: css`
          min-width: 200px;
        `,
      },
      {
        key: "specify",
        label: "商品規格",
        cusStyle: css`
          min-width: 210px;
        `,
      },
      {
        key: "stock_action",
        label: "庫存操作",
        cusStyle: css`
          min-width: 120px;
        `,
      },
      {
        key: "quantity",
        label: "數量",
        cusStyle: css`
          min-width: 120px;
        `,
      },
      {
        key: "stockQuantity",
        label: "庫存數",
        cusStyle: css`
          min-width: 120px;
        `,
      },
      {
        key: "action",
        label: "操作",
        cusStyle: css`
          min-width: 110px;
        `,
      },
    ];

    const bodyConfig = [
      {
        bodyComp: ({ date }, { tdIndex, rowIndex }) => (
          <TdComponent key={`td-${rowIndex}-${tdIndex}`} align="center">
            {date}
          </TdComponent>
        ),
      },
      {
        bodyComp: ({ productName }, { tdIndex, rowIndex }) => (
          <TdComponent key={`td-${rowIndex}-${tdIndex}`} align="center">
            {productName}
          </TdComponent>
        ),
      },
      {
        bodyComp: ({ specifyName, specifyId }, { tdIndex, rowIndex }) => (
          <TdComponent key={`td-${rowIndex}-${tdIndex}`} align="center">
            {Boolean(specifyId) ? specifyName : "單一規格"}
          </TdComponent>
        ),
      },
      {
        bodyComp: ({ type = "" }, { tdIndex, rowIndex }) => (
          <TdComponent key={`td-${rowIndex}-${tdIndex}`} align="center">
            {STCOK_OPERATION_TYPES[type.toString()]}
          </TdComponent>
        ),
      },
      {
        bodyComp: ({ quantity }, { tdIndex, rowIndex }) => (
          <TdComponent key={`td-${rowIndex}-${tdIndex}`} align="center">
            {Number(quantity).toLocaleString()}
          </TdComponent>
        ),
      },
      {
        bodyComp: ({ stockQuantity }, { tdIndex, rowIndex }) => (
          <TdComponent key={`td-${rowIndex}-${tdIndex}`} align="center">
            {Number(stockQuantity).toLocaleString()}
          </TdComponent>
        ),
      },
      {
        bodyComp: (record, { tdIndex, rowIndex }) => (
          <TdComponent
            key={`td-${rowIndex}-${tdIndex}`}
            align="center"
            isAction
          >
            <RecoveryButton
              onClick={() => {
                onRecoveryButtonClick(record);
              }}
            />
          </TdComponent>
        ),
      },
    ];

    return (
      <WrapperComponent
        headerConfig={headerConfig}
        bodyConfig={bodyConfig}
        data={tableData}
      />
    );
  };
}
