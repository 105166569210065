import { IS_LOCAL } from "core/env";
import { DELETE, fetches, generateFetchOpts } from "./util";
import { MOCK_DATA } from "./mock";
import queryString from "query-string";

// 我的商品 - 取得我的商品列表
export const getProductListApi = async ({ page, payload, perPage }) => {
  const qs = queryString.stringify({ ...payload, page, perPage });
  console.log("我的商品 - 取得列表 QueryString:", qs);
  console.log("我的商品 - 取得列表資料格式:", MOCK_DATA.MY_PRODUCT_LIST);

  if (IS_LOCAL) return MOCK_DATA.MY_PRODUCT_LIST;
  return fetches(`?${qs}`, generateFetchOpts());
};

// 我的商品 - 刪除商品規格
export const deleteProductApi = async (payload) => {
  console.log("我的商品 - 刪除商品規格 payload:", payload);

  if (IS_LOCAL) return;
  return fetches(
    "",
    generateFetchOpts({
      method: DELETE,
      body: payload,
    })
  );
};
