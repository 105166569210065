import Paper from "components/Common/Paper";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { useState } from "react";
import NavigateBar from "components/Layout/NavigateBar";
import ProductEditor from "components/Common/ProductEditor";
import { useNavigate } from "react-router-dom";
import ContentWrpper from "components/Layout/ContentWrpper";
import { validateProductSave } from "core/validate";
import { useDispatch } from "react-redux";
import { setErrorToast, setSuccessToast } from "feature/redux/slice/toast";
import { createAndUpdateProductInfoApi } from "api/editProduct";
import withEditTableContainer from "components/Table/Hoc/withEditTableContainer";
import Table from "components/Table/Table";
import useGenerateTableData from "hook/useGenerateTableData";
import CancelButton from "components/Button/CancelButton";
import SaveButton from "components/Button/SaveButton";

const FunctionTable = withEditTableContainer(Table);

// 新增商品
export default function CreateProduct() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState({});
  const [productName, setProductName] = useState("");
  const [specifyData, setSpecifyData] = useState([]);
  const { tableData, setTableData } = useGenerateTableData(specifyData);

  const handleSave = async () => {
    if (validateProductSave({ productName, specifyData }, setErrorMessage)) {
      try {
        // TODO : 新增/編輯商品 - 儲存商品規格
        await createAndUpdateProductInfoApi({
          productName,
          specifyData,
          tableData,
        });
        dispatch(setSuccessToast("儲存成功"));
      } catch (error) {
        dispatch(setErrorToast("儲存失敗"));
      }
    }
  };

  const handleCancel = () => {
    navigate("/product-manage/my-product");
  };

  return (
    <Grid container>
      <Grid xs={12} sx={{ padding: "0" }}>
        <NavigateBar>
          <CancelButton onClick={handleCancel} />
          <SaveButton variant="contained" onClick={handleSave} />
        </NavigateBar>
      </Grid>
      <ContentWrpper>
        <ProductEditor
          errorMessage={errorMessage}
          productName={productName}
          specifyData={specifyData}
          setSpecifyData={setSpecifyData}
          setProductName={setProductName}
          isCreate
        />
        <Grid xs={12} sx={{ paddingTop: "30px" }}>
          <Paper>
            <FunctionTable tableData={tableData} setTableData={setTableData} />
          </Paper>
        </Grid>
      </ContentWrpper>
    </Grid>
  );
}
