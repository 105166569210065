import BasicButton from "./BasicButton";
import AddIcon from "@mui/icons-material/Add";

export default function AddButton({
  cusStyle,
  onClick = () => {},
  variant = "outlined",
  label = "新增",
}) {
  return (
    <BasicButton
      onClick={onClick}
      cusStyle={cusStyle}
      startIcon={<AddIcon />}
      variant={variant}
      label={label}
    />
  );
}
