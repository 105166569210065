import { css } from "@emotion/react";
import { Typography } from "@mui/material";

// styleName: 按鈕文字;
export default function ButtonText({ children, cusStyle }) {
  return <Typography css={css([basicStyle, cusStyle])}>{children}</Typography>;
}

const basicStyle = css`
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  line-height: 1;
`;
