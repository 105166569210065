import { css } from "@emotion/react";
import FiledInput from "components/Input/FieldInput";
import Title from "components/Typography/Title";
import { fullWidthStyle } from "style/common";

export default function LabelInput({
  value = "",
  onChange = () => {},
  placeholder,
  maxLength,
  label,
  paddingRight,
}) {
  return (
    <>
      <Title cusStyle={titleStyle}>{label}</Title>
      <div css={css([fullWidthStyle, paddingRightStyle(paddingRight)])} className="label-input-wrapper">
        <FiledInput
          onChange={(e) => onChange(e?.target?.value)}
          value={value}
          placeholder={placeholder}
          maxLength={maxLength}
          inputLength={value?.length || 0}
        />
      </div>
    </>
  );
}

const titleStyle = css`
  min-width: 58px;
  padding-right: 16px;
`;

const paddingRightStyle = (paddingRight) => css`
  ${Boolean(paddingRight) && `padding-right:${paddingRight}px;`}
`;
