import { css } from "@emotion/react";
import FiledInput from "components/Input/FieldInput";

// 表格內輸入框
export default function TableInput({ value, onChange = () => {} }) {
  return (
    <td>
      <div css={css(inputWrapperStyle)}>
        <FiledInput
          value={value}
          onChange={(e) => {
            onChange(e?.target?.value);
          }}
        />
      </div>
    </td>
  );
}

const inputWrapperStyle = css`
  width: calc(100% - 16px);
  height: calc(100% - 16px);
  margin: 8px;
`;
