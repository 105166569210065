import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { css } from "@emotion/react";
import {
  flexCenter,
  flexHorizontalSpaceBetween,
  flexHorizontalCenter,
} from "style/flex";
import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SIDEBAR_LIST } from "config/Sidebar";
import SideBarTitle from "components/Typography/SideBarTitle";

export default function SideBarList() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [list, setList] = useState(SIDEBAR_LIST);

  // 自動開啟當前路由選單
  useEffect(() => {
    setList((c) => {
      return [...c].map((item) => {
        const { link: itemLink, extend = [] } = item;
        return itemLink === pathname ||
          extend.filter(({ link: extendLink }) => extendLink === pathname)
          ? { ...item, isExtended: true }
          : item;
      });
    });
  }, [pathname]);

  const handleClick = ({ link, label }) => {
    if (Boolean(link)) navigate(link);
    else {
      setList((c) => {
        return [...c].map((config) => {
          const { label: mainLabel } = config;

          return label === mainLabel
            ? { ...config, isExtended: !config.isExtended }
            : config;
        });
      });
    }
  };

  return (
    <div css={css(wrapperStyle)}>
      {list.map(({ label, Icon, link, isExtended, extend = [] }) => (
        <Fragment key={label}>
          <div
            css={css([mainWrapperStyle, flexHorizontalSpaceBetween])}
            onClick={() => handleClick({ label, link })}
          >
            <div css={css(flexHorizontalCenter)}>
              <div css={css(flexCenter, iconWrapperStyle)}>
                <Icon />
              </div>
              <div>
                <SideBarTitle>{label}</SideBarTitle>
              </div>
            </div>
            {extend.length > 0 ? (
              isExtended ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )
            ) : null}
          </div>
          {isExtended &&
            extend.map(({ label, link }) => (
              <div
                css={css([extendWrapperStyle, flexHorizontalSpaceBetween])}
                key={label}
                onClick={() => handleClick({ link, label })}
              >
                <div css={css(flexHorizontalCenter)}>
                  <SideBarTitle cusStyle={noIconTextStyle}>
                    {label}
                  </SideBarTitle>
                </div>
              </div>
            ))}
        </Fragment>
      ))}
    </div>
  );
}

const noIconTextStyle = css`
  margin-left: 36px;
`;

const wrapperStyle = css`
  margin-top: 60px;
  padding: 4px 24px 16px;
`;

const mainWrapperStyle = css`
  cursor: pointer;
  width: 100%;
  height: 60px;
`;

const extendWrapperStyle = css`
  width: 100%;
  height: 46px;
  cursor: pointer;
`;

const iconWrapperStyle = css`
  width: 36px;
  height: 36px;
`;
