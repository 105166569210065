import { css } from "@emotion/react";
import { Dialog } from "@mui/material";
import CancelButton from "components/Button/CancelButton";
import ConfirmButton from "components/Button/ConfirmButton";
import BigTitle from "components/Typography/BigTitle";
import { FONT_COLOR } from "config/Color";
import { flexCenter } from "style/flex";

export default function DeleteSpecifyDialog({
  isOpen = true,
  data = {},
  onConfirm = () => {},
  onCancel = () => {},
}) {
  const { specifyName, options = [] } = data;

  return (
    <Dialog css={css(custStyle)} open={isOpen}>
      <div css={css([flexCenter, titleWrapperStyle])}>
        <BigTitle>刪除提醒</BigTitle>
      </div>
      <div css={css([flexCenter, contentWrapperstyle])}>
        <BigTitle>
          刪除規格會一併將庫存數量歸零
          <br />
          您確定要刪除此規格嗎?
          <br />
          {specifyName}
          {options.length > 0 ? "：" : ""}
          {options.map(
            ({ name }, i) => `${name}${i !== 0 && i % 2 === 0 ? "、" : ""}`
          )}
        </BigTitle>
      </div>
      <div css={css([flexCenter, actionWrapperStyle])}>
        <CancelButton variant="outlined" onClick={onCancel} />
        <ConfirmButton onClick={onConfirm} />
      </div>
    </Dialog>
  );
}

const actionWrapperStyle = css`
  button {
    margin: 0 5px;
  }
`;

const custStyle = css`
  .MuiPaper-root {
    width: 400px;
    padding: 16px 24px;
  }
`;

const titleWrapperStyle = css`
  border-bottom: 1px solid ${FONT_COLOR.ONE};
  padding: 8px 0;
`;

const contentWrapperstyle = css`
  padding: 24px 0px;
`;
