import Paper from "components/Common/Paper";
import Title from "components/Typography/Title";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { flexHorizontalCenter, flexStart } from "style/flex";
import { css } from "@emotion/react";
import ContentWrpper from "components/Layout/ContentWrpper";
import NavigateBar from "components/Layout/NavigateBar";
import { useCallback, useEffect, useMemo, useState } from "react";
import FieldsetRadio from "components/Radio/FieldsetRadio";
import { Autocomplete, InputAdornment, TextField } from "@mui/material";
import FiledInput from "components/Input/FieldInput";
import HintText from "components/Typography/HintText";
import { FONT_COLOR } from "config/Color";
import { format } from "date-fns";
import { useDispatch } from "react-redux";
import { setErrorToast, setSuccessToast } from "feature/redux/slice/toast";
import {
  BUTTON_TEXT,
  INVENTORY_TYPE,
  STOCK_OPERATION_RADIO_CONFIG,
  STOCK_HINT,
} from "config/StockManage";
import {
  getExistingStockListApi,
  getStockRecordListApi,
  updateStockRecordApi,
  updateStockRecoveryRecordApi,
} from "api/stock";
import RecoveryStockRecordDialog from "components/Dialog/RecoveryStockRecordDialog";
import withStockTableContainer from "components/Table/Hoc/withStockTableContainer";
import Table from "components/Table/Table";
import UpdateButton from "components/Button/UpdateButton";

const FunctionTable = withStockTableContainer(Table);

// 庫存管理
export default function StockManage() {
  const dispatch = useDispatch();
  const [originData, setOriginData] = useState([]);
  const [type, setType] = useState(INVENTORY_TYPE.INVENTORY_CHECK);
  const [productId, setProductId] = useState("");
  const [specifyId, setSpecifyId] = useState("");
  const [count, setCount] = useState(0);
  const [description, setDescription] = useState("");
  const [nameList, setNameList] = useState([]);
  const [specifyList, setSpecifyList] = useState([]);
  const [inputSpecify, setInputSpecify] = useState("");
  const [tableData, setTableData] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [isShowRecoveryDialog, setShowRecoverDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    dateTime: "",
    productId: "",
    specifyId: "",
    count: "",
  });

  const stockCount = useMemo(() => {
    if (originData.length > 0 && Boolean(productId)) {
      const targetArray = originData.filter(
        ({ productId: originalProductId }) => productId === originalProductId
      );
      if (targetArray.length === 0) {
        return undefined;
      } else if (Boolean(specifyId)) {
        const target = targetArray[0];
        if (Boolean(target?.specifyData)) {
          return (
            target?.specifyData?.filter(({ id }) => id === specifyId)[0]
              ?.counts || undefined
          );
        } else return target?.counts || undefined;
      } else return undefined;
    }
  }, [originData, productId, specifyId]);

  const handleRecoverClick = (row) => {
    setSelectedData({ ...row, currentStock: stockCount });
    setShowRecoverDialog(true);
  };

  const handleRecoveryConfirm = async () => {
    try {
      // TODO : 庫存管理頁面 - 復原紀錄
      const { historyUuid } = selectedData;
      await updateStockRecoveryRecordApi(historyUuid);
      setSelectedData({});
      setShowRecoverDialog(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleRecoveryCancel = () => {
    setSelectedData({});
    setShowRecoverDialog(false);
  };

  const validate = () => {
    const errObj = { dateTime: "", productId: "", specifyId: "", count: "" };

    if (!Boolean(productId)) errObj.productId = "請選擇商品搜尋";
    if (!Boolean(specifyId)) errObj.specifyId = "請選擇商品規格";
    if (!(Boolean(count) || count === 0)) errObj.count = "請輸入庫存數量";

    setErrorMessage(errObj);
    return Object.values(errObj).every((r) => r === "");
  };

  const handleUpdate = async () => {
    try {
      // TODO：串接 更新庫存資料 Api
      if (validate()) {
        await updateStockRecordApi({
          productId,
          count,
          description,
          type,
          date: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
          ...(Boolean(specifyId) && specifyId !== "none" ? { specifyId } : {}),
        });

        dispatch(setSuccessToast("更新成功"));
      }
    } catch (error) {
      dispatch(setErrorToast("更新失敗"));
    }
  };

  const handleCountChange = (e) => {
    const {
      target: { value },
    } = e;

    if (value !== "" && Number(value) < 0) return;

    setCount(value === "" ? "" : Number(value));
  };

  const handleProductIdChange = async (newValue) => {
    setProductId(newValue?.value);
    if (Boolean(newValue?.value)) {
      const targetData =
        originData.filter(
          ({ productId }) => productId === newValue?.value
        )[0] || {};
      if (Boolean(targetData.specifyData)) {
        const list = targetData.specifyData.map(({ id, specifyName }) => ({
          label: specifyName,
          value: id,
        }));
        setSpecifyList(list);
      } else
        setSpecifyList([
          {
            label: "單一規格",
            value: "none",
          },
        ]);
    } else setSpecifyList([]);
    setSpecifyId("");
  };

  const getStockRecordList = useCallback(async () => {
    try {
      if (Boolean(specifyId) && Boolean(productId)) {
        // TODO : 庫存管理頁面 - 庫存紀錄列表
        const resp = await getStockRecordListApi({
          productId,
          ...(Boolean(specifyId) && specifyId !== "none" ? { specifyId } : {}),
        });
        setTableData(resp);
      }
    } catch (error) {
      console.log(error);
    }
  }, [productId, specifyId]);

  useEffect(() => {
    if (Boolean(specifyId)) getStockRecordList();
  }, [getStockRecordList, specifyId]);

  useEffect(() => {
    setSpecifyId("");
    setInputSpecify("");
  }, [specifyList]);

  useEffect(() => {
    const getData = async () => {
      try {
        // TODO : 庫存管理頁面 - 取得商品名稱、規格、現有庫存資料
        const resp = (await getExistingStockListApi()) || [];
        const nameList = resp.map(({ productName, productId }) => ({
          label: productName,
          value: productId,
        }));
        setOriginData(resp);
        setNameList(nameList);
      } catch (error) {
        dispatch(setErrorToast("取得資料失敗"));
      }
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container>
      <Grid xs={12} sx={{ padding: "0" }}>
        <NavigateBar />
      </Grid>
      <ContentWrpper>
        <Grid xs={12}>
          <Paper>
            <Grid container spacing={1}>
              <Grid xs={12} css={css([flexHorizontalCenter, wrapperStyle])}>
                <Title cusStyle={[inputTitleStyle]}>庫存操作</Title>
              </Grid>
              <Grid
                xs={12}
                css={css([flexStart, wrapperStyle, radioWrapperStyle])}
              >
                {STOCK_OPERATION_RADIO_CONFIG.map(({ title, list = [] }) => (
                  <FieldsetRadio
                    title={title}
                    config={list}
                    key={title}
                    activeValue={type}
                    onChange={setType}
                  />
                ))}
              </Grid>
              <Grid
                xs={12}
                css={css([
                  flexHorizontalCenter,
                  inputWrapperStyle,
                  wrapperStyle,
                ])}
              >
                <Title cusStyle={inputTitleStyle}>商品搜尋</Title>
                <Autocomplete
                  disablePortal
                  onChange={(_event, newValue) => {
                    handleProductIdChange(newValue);
                  }}
                  disableClearable
                  noOptionsText="無匹配資料"
                  options={nameList || []}
                  css={css(autocompleteStyle)}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="輸入商品來搜尋" />
                  )}
                />
              </Grid>
              {Boolean(errorMessage?.productId) && (
                <Grid
                  xs={12}
                  sx={{
                    marginTop: "-16px",
                    paddingLeft: "80px",
                  }}
                >
                  <div css={css(errorStyle)}>{errorMessage?.productId}</div>
                </Grid>
              )}
              <Grid
                xs={12}
                css={css([
                  flexHorizontalCenter,
                  inputWrapperStyle,
                  wrapperStyle,
                ])}
              >
                <Title cusStyle={inputTitleStyle}>商品規格</Title>
                <Autocomplete
                  disablePortal
                  onChange={(_event, newValue) => {
                    setSpecifyId(newValue?.value);
                    setInputSpecify(newValue?.label);
                  }}
                  onClose={(e, reason) => {
                    if (reason === "selectOption")
                      setInputSpecify(e.target.textContent);
                    else {
                      const target = specifyList.filter(
                        ({ value }) => value === specifyId
                      );
                      if (target.length !== 0) setInputSpecify(target[0].label);
                    }
                  }}
                  disableClearable
                  inputValue={inputSpecify}
                  noOptionsText="無匹配資料"
                  options={specifyList || []}
                  css={css(autocompleteStyle)}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        onChange={(e) => {
                          setInputSpecify(e?.target?.value);
                        }}
                        placeholder="輸入規格來搜尋"
                      />
                    );
                  }}
                />
              </Grid>
              {Boolean(errorMessage?.specifyId) && (
                <Grid
                  xs={12}
                  sx={{
                    marginTop: "-16px",
                    paddingLeft: "80px",
                  }}
                >
                  <div css={css(errorStyle)}>{errorMessage?.specifyId}</div>
                </Grid>
              )}
              <Grid
                xs={12}
                css={css([
                  flexHorizontalCenter,
                  inputWrapperStyle,
                  wrapperStyle,
                ])}
              >
                <Title cusStyle={inputTitleStyle}>{STOCK_HINT[type]}</Title>
                <FiledInput
                  type="number"
                  placeholder={"請輸入"}
                  value={count}
                  onChange={handleCountChange}
                  css={css(widthStyle())}
                  outerStyle={stockCountStyle}
                />
                {stockCount !== undefined && (
                  <HintText cusStyle={hintStyle}>
                    現有庫存：{stockCount}
                  </HintText>
                )}
              </Grid>
              {Boolean(errorMessage?.count) && (
                <Grid
                  xs={12}
                  sx={{
                    marginTop: "-16px",
                    paddingLeft: "80px",
                  }}
                >
                  <div css={css(errorStyle)}>{errorMessage?.count}</div>
                </Grid>
              )}
              {Boolean(errorMessage?.dateTime) && (
                <Grid
                  xs={12}
                  sx={{
                    marginTop: "-16px",
                    paddingLeft: "80px",
                  }}
                >
                  <div css={css(errorStyle)}>{errorMessage?.dateTime}</div>
                </Grid>
              )}
              <Grid xs={12} css={css([flexHorizontalCenter, wrapperStyle])}>
                <Title cusStyle={inputTitleStyle}>備註</Title>
                <TextField
                  value={description}
                  onChange={(e) => setDescription(e?.target?.value)}
                  multiline
                  minRows={2}
                  fullWidth
                  css={css(widthStyle(true))}
                  placeholder="請輸備註事項"
                  inputProps={{ maxLength: 500 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment css={css(adornmentStyle)} position="end">
                        <p>{description.length}/500</p>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid xs={12} css={css([flexHorizontalCenter, wrapperStyle])}>
                <UpdateButton
                  variant="contained"
                  onClick={handleUpdate}
                  label={BUTTON_TEXT[type]}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>{" "}
        <Grid xs={12} sx={{ paddingTop: "30px" }}>
          <Paper>
            <div css={css([flexHorizontalCenter, titleWrapperStyle])}>
              <Title>庫存更新紀錄</Title>
            </div>
            <FunctionTable
              tableData={tableData}
              onRecoveryButtonClick={handleRecoverClick}
            />
          </Paper>
        </Grid>
      </ContentWrpper>
      {isShowRecoveryDialog && (
        <RecoveryStockRecordDialog
          onCancel={handleRecoveryCancel}
          onConfirm={handleRecoveryConfirm}
          data={selectedData}
        />
      )}
    </Grid>
  );
}

const widthStyle = (isMark) =>
  css`
    width: 100%;
    ${!isMark ? "max-width: 400px" : ""};
    min-width: 300px;
  `;

const titleWrapperStyle = css`
  padding-bottom: 10px;
`;

const inputTitleStyle = css`
  min-width: 58px;
  padding-right: 16px;
  text-align-last: justify;
`;

const inputWrapperStyle = css`
  div.MuiTextField-root {
    width: 100%;
    max-width: 400px;
    min-width: 300px;
    height: 40px;
  }
`;

const wrapperStyle = css`
  padding-bottom: 16px;
`;

const radioWrapperStyle = css`
  fieldset {
    margin: 0 16px 0 0;
  }
`;

const autocompleteStyle = css`
  width: 100%;
  max-width: 400px;
  min-width: 200px;
  .MuiInputBase-root {
    height: 36px;
  }
  input.MuiInputBase-input {
    padding: 0 !important;
  }
`;

const hintStyle = css`
  color: ${FONT_COLOR.ONE};
  padding-left: 16px;
  text-align: left;
  min-width: 120px;
`;

const adornmentStyle = css`
  p {
    font-size: 12px;
    margin: unset;
    line-height: 1;
  }
`;

const stockCountStyle = css`
  max-width: 400px;
`;

const errorStyle = css`
  color: red;
  min-width: 100px;
`;
