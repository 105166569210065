import BasicButton from "./BasicButton";
import CloseIcon from '@mui/icons-material/Close';

export default function CancelButton({
  onClick = () => {},
  variant = "outlined",
  label = "取消",
}) {
  return (
    <BasicButton
      onClick={onClick}
      startIcon={<CloseIcon />}
      variant={variant}
      label={label}
    />
  );
}
