import { css } from "@emotion/react";
import { Typography } from "@mui/material";

// styleName: 網站標題;
export default function WebSiteTitle({ children, cusStyle }) {
  return <Typography css={css([basicStyle, cusStyle])}>{children}</Typography>;
}

const basicStyle = css`
  font-family: Inter;
  font-size: 28px;
  font-weight: 400;
  line-height: 38.73px;
  text-align: left;
`;
