import BasicButton from "./BasicButton";
import ArrowCircleUpOutlinedIcon from '@mui/icons-material/ArrowCircleUpOutlined';

export default function UpdateButton({
  cusStyle,
  onClick = () => {},
  variant = "outlined",
  label = "更新",
}) {
  return (
    <BasicButton
      onClick={onClick}
      cusStyle={cusStyle}
      startIcon={<ArrowCircleUpOutlinedIcon />}
      variant={variant}
      label={label}
    />
  );
}
