import { IS_LOCAL } from "core/env";
import { POST, fetches, generateFetchOpts } from "./util";
import { MOCK_DATA } from "./mock";
import queryString from "query-string";

// 新增/編輯商品 - 儲存商品規格
export const createAndUpdateProductInfoApi = async (payload) => {
  console.log("新增/編輯商品 - 儲存商品規格:", payload);
  if (IS_LOCAL) return;
  return fetches("", generateFetchOpts({ method: POST, body: payload }));
};

// 編輯商品 - 取得商品規格
export const getProductInfoApi = async (produectId) => {
  const qs = queryString.stringify({ produectId });
  console.log("編輯商品 - 取得商品規格 QueryString:", qs);
  if (IS_LOCAL) return MOCK_DATA.PRODUCT_INFO;
  return fetches("", generateFetchOpts());
};

// 編輯商品 - 取得商品列表
export const getSpecifyProductListApi = async (produectId) => {
  const qs = queryString.stringify({ produectId });
  console.log("編輯商品 - 取得商品列表 QueryString:", qs);
  console.log(
    "編輯商品 - 取得商品列表無規格格式:",
    MOCK_DATA.TABLE_DATA_NO_SPECIFY
  );
  console.log(
    "編輯商品 - 取得商品列表格式一種規格選項:",
    MOCK_DATA.TABLE_DATA_ONE_SPECIFY
  );
  console.log(
    "編輯商品 - 取得商品列表兩種規格選項格式:",
    MOCK_DATA.TABLE_DATA_TWO_SPECIFY
  );
  if (IS_LOCAL) return MOCK_DATA.TABLE_DATA_TWO_SPECIFY;
  return fetches("", generateFetchOpts());
};
