import { IS_LOCAL } from "core/env";
import { POST, fetches, generateFetchOpts } from "./util";
import { MOCK_DATA } from "./mock";
import queryString from "query-string";

// 庫存管理頁面 - 取得商品名稱、規格、現有庫存資料
export const getExistingStockListApi = async () => {
  console.log(
    "庫存管理頁面 - 取得商品名稱、規格、現有庫存資料格式:",
    MOCK_DATA.EXISTING_STOCK_LIST
  );
  if (IS_LOCAL) return MOCK_DATA.EXISTING_STOCK_LIST;
  return fetches(``, generateFetchOpts());
};

// 庫存管理頁面 - 取得庫存紀錄列表
export const getStockRecordListApi = async (payload) => {
  const qs = queryString.stringify(payload);
  console.log("庫存管理頁面 - 取得庫存紀錄列表 QueryString:", qs);
  console.log(
    "庫存管理頁面 - 取得庫存紀錄列表資料格式:",
    MOCK_DATA.STOCK_RECORD_LIST
  );

  if (IS_LOCAL) return MOCK_DATA.STOCK_RECORD_LIST;
  return fetches(`?${qs}`, generateFetchOpts());
};

// 庫存管理頁面 - 復原紀錄
export const updateStockRecoveryRecordApi = async (historyUuid) => {
  console.log("庫存管理頁面 - 復原紀錄 payload:", { historyUuid });

  if (IS_LOCAL) return MOCK_DATA.STOCK_RECORD_LIST;
  return fetches(
    ``,
    generateFetchOpts({
      method: POST,
      body: {
        historyUuid,
      },
    })
  );
};

// 庫存管理頁面 - 更新紀錄
export const updateStockRecordApi = async (payload) => {
  console.log("庫存管理頁面 - 更新紀錄 payload:", payload);

  if (IS_LOCAL) return MOCK_DATA.STOCK_RECORD_LIST;
  return fetches(
    ``,
    generateFetchOpts({
      method: POST,
      body: payload,
    })
  );
};
