import { css } from "@emotion/react";
import Paper from "components/Common/Paper";
import { flexHorizontalCenter } from "style/flex";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import FieldInput from "components/Input/FieldInput";
import { useMemo, useState } from "react";
import Title from "components/Typography/Title";
import SpecificationBlock from "components/Common/SpecificationBlock";
import DeleteSpecifyDialog from "components/Dialog/DeleteSpecifyDialog";
import { fullWidthStyle } from "style/common";
import { OPTIONS, SPECIFY_NAME, SPECIFY_UUID } from "config/Key";
import AddButton from "components/Button/AddButton";

export default function ProductEditor({
  productName = {},
  specifyData = [],
  setProductName = () => {},
  setSpecifyData = () => {},
  errorMessage,
  isCreate,
}) {
  const [isShowDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectedMainIndex, setSelectedMainIndex] = useState(null);
  const [selecteOptionIndex, setSelectedOptionIndex] = useState(null);
  const [selectedSpecifyData, setSelectedSpecifyData] = useState({});
  const specifyCounts = useMemo(() => specifyData.length, [specifyData]);
  const generateSpecifyUuid = () => `local-specify-${crypto.randomUUID()}`;
  const generateOptionUuid = () => `local-option-${crypto.randomUUID()}`;

  // 新增規格
  const handleAddSpecification = () => {
    setSpecifyData((c) => [
      ...(c.length === 1 ? c : []),
      {
        [SPECIFY_NAME]: "",
        [SPECIFY_UUID]: generateSpecifyUuid(),
        [OPTIONS]: [
          {
            name: "",
            id: generateOptionUuid(),
          },
        ],
      },
    ]);
  };

  // 點擊刪除規格按鈕
  const handleDeleteSpecificationClick = (mainIndex) => {
    if (isCreate) handleDeleteSpecification(mainIndex);
    else {
      setSelectedMainIndex(mainIndex);
      setSelectedSpecifyData(specifyData[mainIndex]);
      setShowDeleteDialog(true);
    }
  };

  // 刪除規格
  const handleDeleteSpecification = (mainIndex) => {
    setSpecifyData((c) =>
      c.length === 1
        ? []
        : specifyData.filter((_, index) => index !== mainIndex)
    );
    if (!isCreate) setShowDeleteDialog(false);

    handleDeleteReset();
  };

  // 點擊刪除選項按鈕
  const handleDeleteOptionClick = (mainIndex, optionIndex) => {
    if (isCreate) handleDeleteOption(mainIndex, optionIndex);
    else {
      setSelectedMainIndex(mainIndex);
      setSelectedOptionIndex(optionIndex);
      setSelectedSpecifyData({
        ...specifyData[mainIndex],
        [OPTIONS]: [specifyData[mainIndex][OPTIONS][optionIndex]],
      });
      setShowDeleteDialog(true);
    }
  };

  // 刪除選項
  const handleDeleteOption = (mainIndex, optionIndex) => {
    setSpecifyData((c) => {
      return [
        ...c.map((item, index) =>
          index === mainIndex
            ? {
                ...item,
                [OPTIONS]: item.options.filter((_, i) => i !== optionIndex),
              }
            : item
        ),
      ];
    });
    handleDeleteReset();
  };

  // 新增選項
  const handleAddOption = (mainIndex) => {
    setSpecifyData((c) => {
      const { [OPTIONS]: options = [], ...rest } = c[mainIndex];

      return c.map((item, index) =>
        index === mainIndex
          ? {
              ...rest,
              [OPTIONS]: [
                ...options,
                {
                  name: "",
                  id: `local-option-${crypto.randomUUID()}`,
                },
              ],
            }
          : item
      );
    });
  };

  const handleChange = ({ mainIndex, optionIndex, value }) => {
    setSpecifyData((c) => {
      return c.map((item, index) => {
        if (index === mainIndex) {
          if (optionIndex === undefined) {
            // optionIndex 為 undefined 代表是異動規格名稱
            return { ...item, ...{ [SPECIFY_NAME]: value } };
          } else {
            // 有 optionIndex 代表是異動選項名稱
            return {
              ...item,
              [OPTIONS]: item[OPTIONS].map(({ name, ...rest }, i) => ({
                name: i === optionIndex ? value : name,
                ...rest,
              })),
            };
          }
        } else return item;
      });
    });
  };

  const handleDeleteReset = () => {
    setShowDeleteDialog(false);
    setSelectedMainIndex(null);
    setSelectedOptionIndex(null);
    setSelectedSpecifyData({});
  };

  const handleProductNameChange = (e) => {
    setProductName(e?.target?.value);
  };

  return (
    <Grid xs={12}>
      <Paper>
        <Grid container>
          <Grid xs={12} display={"flex"} css={css(titleWrapperStyle)}>
            <Title cusStyle={titleStyle}>商品名稱</Title>
            <FieldInput
              error={errorMessage?.productName}
              onChange={handleProductNameChange}
              value={productName}
              placeholder={"輸入商品名稱"}
              maxLength={200}
              inputLength={productName?.length || 0}
            />
          </Grid>
          {specifyData.length > 0 && (
            <Grid xs={12} display={"flex"}>
              <Title cusStyle={titleStyle}>規格</Title>
              <div css={css(fullWidthStyle)}>
                {specifyData.map((item, index) => (
                  <SpecificationBlock
                    key={index}
                    errorMessage={errorMessage?.specifyData?.[index]}
                    index={index}
                    data={item}
                    onChange={handleChange}
                    onDeleteSpecification={handleDeleteSpecificationClick}
                    onAddOption={handleAddOption}
                    onDeleteOption={handleDeleteOptionClick}
                  />
                ))}
              </div>
            </Grid>
          )}
          {specifyCounts < 2 && (
            <Grid
              xs={12}
              css={css([flexHorizontalCenter])}
              sx={{ paddingTop: "16px" }}
            >
              <AddButton
                onClick={handleAddSpecification}
                label={"新增規格"}
              />
            </Grid>
          )}
        </Grid>
      </Paper>

      <DeleteSpecifyDialog
        isOpen={isShowDeleteDialog}
        data={selectedSpecifyData}
        onConfirm={() => {
          if (selecteOptionIndex !== null)
            handleDeleteOption(selectedMainIndex, selecteOptionIndex);
          else handleDeleteSpecification(selectedMainIndex);
        }}
        onCancel={handleDeleteReset}
      />
    </Grid>
  );
}

const titleStyle = css`
  min-width: 58px;
  padding-right: 16px;
  margin-top: 12px;
  text-align: end;
`;

const titleWrapperStyle = css`
  .field-input-wrapper {
    min-width: 300px;
  }
`;
