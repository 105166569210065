export const isEmptyText = (text) =>
  typeof text === "string" && text.trim().length > 0 ? false : true;

export const validateEmptyText = (text) =>
  isEmptyText(text) ? "此欄位為必填欄位" : "";

export const isPositiveNumber = (number) =>
  typeof number === "number" && number > 0 ? true : false;

export const validatePositiveNumber = (number) =>
  isPositiveNumber(number) ? "" : "請輸入大於 0 的數字";

// 儲存商品檢查
export const validateProductSave = (data = {}, setErrorMessage = () => {}) => {
  let errorObj = {};
  let hasError = false;
  const hasSpecifyData = data.specifyData.length > 0;

  if (hasSpecifyData) {
    errorObj = {
      productName: validateEmptyText(data.productName),
      specifyData: data.specifyData.map(({ specifyName, options = [] }) => ({
        specifyName: validateEmptyText(specifyName),
        options: options.map(({ name }) => ({
          name: validateEmptyText(name),
        })),
      })),
    };
    errorObj.specifyData.forEach(({ specifyName, options = [] }) => {
      if (hasError) return;

      if (Boolean(specifyName)) hasError = true;
      if (
        options.some((item) => Object.values(item).some((err) => Boolean(err)))
      )
        hasError = true;
    });
  } else {
    errorObj = {
      productName: validateEmptyText(data.productName),
    };
    hasError = Object.values(errorObj).some((k) => Boolean(k));
  }
  setErrorMessage(() => errorObj);

  // 返回是否通過
  return !hasError;
};
