import { css } from "@emotion/react";
import { Typography } from "@mui/material";

// styleName: 表格內容;
export default function TableContentText({
  children,
  textAlign = "left",
  cusStyle,
}) {
  return (
    <Typography
      css={css([
        basicStyle(textAlign),
        ...(Boolean(cusStyle) ? [cusStyle] : []),
      ])}
    >
      {children}
    </Typography>
  );
}

const basicStyle = (textAlign) => css`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
  text-align: ${textAlign};
`;
