// 表格 KEY
// 規格一 Id
export const SPECIFY_ONE_ID = "specifyOneId";
// 規格一 名稱
export const SPECIFY_ONE = "specifyOneName";
// 規格一 選項名稱
export const OPTION_ONE = "optionOne";
// 規格一 選項Id
export const OPTION_ONE_ID = "optionOneId";
// 規格二 名稱
export const SPECIFY_TWO_ID = "specifyTwoId";
// 規格二 Id
export const SPECIFY_TWO = "specifyTwoName";
// 規格二 選項名稱
export const OPTION_TWO = "optionTwo";
// 規格二 選項Id
export const OPTION_TWO_ID = "optionTwoId";
// 進貨價格
export const PURCHASE_PRICE = "purchasePrice";
// 商品數量
export const QUANTITY = "quantity";
// 售價
export const SALE_PRICE = "salePrice";

// 規格區塊 KEY
// 規格名稱
export const SPECIFY_NAME = "specifyName";
// 規格 UUID
export const SPECIFY_UUID = "specifyUuid";
// 選項資料
export const OPTIONS = "options";
