import BasicButton from "./BasicButton";
import RestoreIcon from "@mui/icons-material/Restore";

export default function RecoveryButton({
  onClick = () => {},
  variant = "outlined",
  label = "復原",
}) {
  return (
    <BasicButton
      onClick={onClick}
      startIcon={<RestoreIcon />}
      variant={variant}
      label={label}
    />
  );
}
