export const NUMBER_TO_ZH = {
  1: "一",
  2: "二",
  3: "三",
  4: "四",
  5: "五",
  6: "六",
  7: "七",
  8: "八",
  9: "九",
  10: "十",
  11: "十一",
  12: "十二",
  13: "十三",
  14: "十四",
  15: "十五",
  16: "十六",
  17: "十七",
  18: "十八",
  19: "十九",
  20: "二十",
  21: "二十一",
  22: "二十二",
  23: "二十三",
  24: "二十四",
  25: "二十五",
  26: "二十六",
  27: "二十七",
  28: "二十八",
  29: "二十九",
  30: "三十",
  31: "三十一",
  32: "三十二",
  33: "三十三",
  34: "三十四",
  35: "三十五",
  36: "三十六",
  37: "三十七",
  38: "三十八",
  39: "三十九",
  40: "四十",
  41: "四十一",
  42: "四十二",
  43: "四十三",
  44: "四十四",
  45: "四十五",
  46: "四十六",
  47: "四十七",
  48: "四十八",
  49: "四十九",
  50: "五十",
};
