export const MOCK_DATA = {
  // 編輯/新增商品頁面 - 商品資訊有規格
  PRODUCT_INFO: {
    productName: "商品名稱一",
    specifyData: [
      {
        specifyName: "顏色",
        specifyUuid: "local-specify-6b41dc30-f5b1-4e07-95e8-71359fb23916",
        options: [
          {
            name: "黑",
            id: "local-option-f88358d5-9e79-4d9e-8eeb-2a5234fbcdf7",
          },
          {
            name: "白",
            id: "local-option-ec128628-6193-4c13-9c07-26a247b06c16",
          },
        ],
      },
      {
        specifyName: "體積",
        specifyUuid: "local-specify-38fcbc9e-0ad3-4d9c-bffe-b7e37719d8a2",
        options: [
          {
            name: "大",
            id: "local-option-b95493f5-5280-4b5c-8026-e35021492a86",
          },
          {
            name: "中",
            id: "local-option-fb72bbac-58c8-4bf0-a0ad-e4e6e323216b",
          },
          {
            name: "小",
            id: "local-option-3c2057d4-499d-47a5-bc87-942b9da88c2c",
          },
        ],
      },
    ],
  },
  // 編輯/新增商品頁面 - 商品列表 - 無規格
  TABLE_DATA_NO_SPECIFY: [
    { purchasePrice: 101, salePrice: 101, quantity: 101 },
  ],
  // 編輯/新增商品頁面 - 商品列表 - 規格1-選項2
  TABLE_DATA_ONE_SPECIFY: [
    {
      specifyOneId: "local-specify-6b41dc30-f5b1-4e07-95e8-71359fb23916",
      specifyOneName: "顏色",
      optionOneId: "local-option-f88358d5-9e79-4d9e-8eeb-2a5234fbcdf7",
      optionOne: "黑",
      purchasePrice: 10,
      salePrice: 10,
      quantity: 10,
    },
    {
      specifyOneId: "local-specify-6b41dc30-f5b1-4e07-95e8-71359fb23916",
      specifyOneName: "顏色",
      optionOneId: "local-option-ec128628-6193-4c13-9c07-26a247b06c16",
      optionOne: "白",
      purchasePrice: 101,
      salePrice: 101,
      quantity: 101,
    },
  ],
  // 編輯/新增商品頁面 - 商品列表 規格1-選項2 & 規格2-選項1
  TABLE_DATA_TWO_SPECIFY2: [
    {
      specifyOneId: "local-specify-6b41dc30-f5b1-4e07-95e8-71359fb23916",
      specifyOneName: "顏色",
      optionOneId: "local-option-f88358d5-9e79-4d9e-8eeb-2a5234fbcdf7",
      optionOne: "黑",
      specifyTwoId: "local-specify-38fcbc9e-0ad3-4d9c-bffe-b7e37719d8a2",
      specifyTwoName: "體積",
      optionTwoId: "local-option-b95493f5-5280-4b5c-8026-e35021492a86",
      optionTwo: "大",
      purchasePrice: 10,
      salePrice: 10,
      quantity: 10,
    },
    {
      specifyOneId: "local-specify-6b41dc30-f5b1-4e07-95e8-71359fb23916",
      specifyOneName: "顏色",
      optionOneId: "local-option-ec128628-6193-4c13-9c07-26a247b06c16",
      optionOne: "白",
      specifyTwoId: "local-specify-38fcbc9e-0ad3-4d9c-bffe-b7e37719d8a2",
      specifyTwoName: "體積",
      optionTwoId: "local-option-b95493f5-5280-4b5c-8026-e35021492a86",
      optionTwo: "大",
      purchasePrice: 101,
      salePrice: 101,
      quantity: 101,
    },
  ],
  // 編輯/新增商品頁面 - 商品列表 規格1-選項2 & 規格2-選項3
  TABLE_DATA_TWO_SPECIFY: [
    {
      specifyOneId: "local-specify-6b41dc30-f5b1-4e07-95e8-71359fb23916",
      specifyOneName: "顏色",
      optionOneId: "local-option-f88358d5-9e79-4d9e-8eeb-2a5234fbcdf7",
      optionOne: "黑",
      specifyTwoId: "local-specify-38fcbc9e-0ad3-4d9c-bffe-b7e37719d8a2",
      specifyTwoName: "體積",
      optionTwoId: "local-option-b95493f5-5280-4b5c-8026-e35021492a86",
      optionTwo: "大",
      purchasePrice: 10,
      salePrice: 10,
      quantity: 10,
    },
    {
      specifyOneId: "local-specify-6b41dc30-f5b1-4e07-95e8-71359fb23916",
      specifyOneName: "顏色",
      optionOneId: "local-option-f88358d5-9e79-4d9e-8eeb-2a5234fbcdf7",
      optionOne: "黑",
      specifyTwoId: "local-specify-38fcbc9e-0ad3-4d9c-bffe-b7e37719d8a2",
      specifyTwoName: "體積",
      optionTwoId: "local-option-fb72bbac-58c8-4bf0-a0ad-e4e6e323216b",
      optionTwo: "中",
      purchasePrice: 101,
      salePrice: 101,
      quantity: 101,
    },
    {
      specifyOneId: "local-specify-6b41dc30-f5b1-4e07-95e8-71359fb23916",
      specifyOneName: "顏色",
      optionOneId: "local-option-f88358d5-9e79-4d9e-8eeb-2a5234fbcdf7",
      optionOne: "黑",
      specifyTwoId: "local-specify-38fcbc9e-0ad3-4d9c-bffe-b7e37719d8a2",
      specifyTwoName: "體積",
      optionTwoId: "local-option-3c2057d4-499d-47a5-bc87-942b9da88c2c",
      optionTwo: "小",
      purchasePrice: 102,
      salePrice: 102,
      quantity: 102,
    },
    {
      specifyOneId: "local-specify-6b41dc30-f5b1-4e07-95e8-71359fb23916",
      specifyOneName: "顏色",
      optionOneId: "local-option-ec128628-6193-4c13-9c07-26a247b06c16",
      optionOne: "白",
      specifyTwoId: "local-specify-38fcbc9e-0ad3-4d9c-bffe-b7e37719d8a2",
      specifyTwoName: "體積",
      optionTwoId: "local-option-b95493f5-5280-4b5c-8026-e35021492a86",
      optionTwo: "大",
      purchasePrice: 20,
      salePrice: 20,
      quantity: 20,
    },
    {
      specifyOneId: "local-specify-6b41dc30-f5b1-4e07-95e8-71359fb23916",
      specifyOneName: "顏色",
      optionOne: "白",
      optionOneId: "local-option-ec128628-6193-4c13-9c07-26a247b06c16",
      specifyTwoId: "local-specify-38fcbc9e-0ad3-4d9c-bffe-b7e37719d8a2",
      specifyTwoName: "體積",
      optionTwoId: "local-option-fb72bbac-58c8-4bf0-a0ad-e4e6e323216b",
      optionTwo: "中",
      purchasePrice: 201,
      salePrice: 201,
      quantity: 201,
    },
    {
      specifyOneId: "local-specify-6b41dc30-f5b1-4e07-95e8-71359fb23916",
      specifyOneName: "顏色",
      optionOneId: "local-option-ec128628-6193-4c13-9c07-26a247b06c16",
      optionOne: "白",
      specifyTwoId: "local-specify-38fcbc9e-0ad3-4d9c-bffe-b7e37719d8a2",
      specifyTwoName: "體積",
      optionTwoId: "local-option-3c2057d4-499d-47a5-bc87-942b9da88c2c",
      optionTwo: "小",
      purchasePrice: 202,
      salePrice: 202,
      quantity: 202,
    },
  ],
  // 我的商品頁面 - 列表
  MY_PRODUCT_LIST: {
    totalCounts: 5,
    totalPage: 5,
    data: [
      {
        productId: "3b41dc30-f5b1-4e07-95e8-71359fb23916",
        productName: "商品名稱1",
        specifyOneId: "6b41dc30-f5b1-4e07-95e8-71359fb23916",
        specifyOneName: "顏色",
        optionOneId: "ec128628-6193-4c13-9c07-26a247b06c16",
        optionOne: "白",
        specifyTwoId: "38fcbc9e-0ad3-4d9c-bffe-b7e37719d8a2",
        specifyTwoName: "體積",
        optionTwoId: "3c2057d4-499d-47a5-bc87-942b9da88c2c",
        optionTwo: "小",
        purchasePrice: 1,
        salePrice: 2,
        quantity: 3,
      },
      {
        productId: "3b41dc30-f5b1-4e07-95e8-71359fb23916",
        productName: "商品名稱1",
        specifyOneId: "6b41dc30-f5b1-4e07-95e8-71359fb23916",
        specifyOneName: "顏色",
        optionOneId: "ec128628-6193-4c13-9c07-26a247b06c13",
        optionOne: "白",
        specifyTwoId: "38fcbc9e-0ad3-4d9c-bffe-b7e37719d8a2",
        specifyTwoName: "體積",
        optionTwoId: "3c2057d4-499d-47a5-bc87-942b9da88c21",
        optionTwo: "大",
        purchasePrice: 11,
        salePrice: 22,
        quantity: 33,
      },
      {
        productId: "3b41dc30-f5b1-4e07-95e8-71359fb23916",
        productName: "商品名稱1",
        specifyOneId: "6b41dc30-f5b1-4e07-95e8-71359fb23916",
        specifyOneName: "顏色",
        optionOneId: "ec128628-6193-4c13-9c07-26a247b06c13",
        optionOne: "黑",
        specifyTwoId: "38fcbc9e-0ad3-4d9c-bffe-b7e37719d8a2",
        specifyTwoName: "體積",
        optionTwoId: "3c2057d4-499d-47a5-bc87-942b9da88c2c",
        optionTwo: "小",
        purchasePrice: 111,
        salePrice: 222,
        quantity: 333,
      },
      {
        productId: "3b41dc30-f5b1-4e07-95e8-71359fb23916",
        productName: "商品名稱1",
        specifyOneId: "6b41dc30-f5b1-4e07-95e8-71359fb23916",
        specifyOneName: "顏色",
        optionOneId: "ec128628-6193-4c13-9c07-26a247b06c13",
        optionOne: "黑",
        specifyTwoId: "38fcbc9e-0ad3-4d9c-bffe-b7e37719d8a2",
        specifyTwoName: "體積",
        optionTwoId: "3c2057d4-499d-47a5-bc87-942b9da88c21",
        optionTwo: "大",
        purchasePrice: 1111,
        salePrice: 2222,
        quantity: 3333,
      },
      {
        productId: "3b41dc30-f5b1-4e07-95e8-71359fb23916",
        productName: "商品名稱2",
        purchasePrice: 11111,
        salePrice: 22222,
        quantity: 33333,
      },
    ],
  },

  // 商品表現清單
  PERFORMANCE_LIST: {
    totalCounts: 5,
    totalPage: 5,
    data: [
      {
        productId: "3b41dc30-f5b1-4e07-95e8-71359fb23916",
        productName: "商品名稱1",
        specifyOneId: "6b41dc30-f5b1-4e07-95e8-71359fb23916",
        specifyOneName: "顏色",
        optionOneId: "ec128628-6193-4c13-9c07-26a247b06c16",
        optionOne: "白",
        specifyTwoId: "38fcbc9e-0ad3-4d9c-bffe-b7e37719d8a2",
        specifyTwoName: "體積",
        optionTwoId: "3c2057d4-499d-47a5-bc87-942b9da88c2c",
        optionTwo: "小",
        currentStock: 1,
        totalStockOut: 2,
        filterStockOut: 3,
      },
      {
        productId: "3b41dc30-f5b1-4e07-95e8-71359fb23916",
        productName: "商品名稱1",
        specifyOneId: "6b41dc30-f5b1-4e07-95e8-71359fb23916",
        specifyOneName: "顏色",
        optionOneId: "ec128628-6193-4c13-9c07-26a247b06c13",
        optionOne: "白",
        specifyTwoId: "38fcbc9e-0ad3-4d9c-bffe-b7e37719d8a2",
        specifyTwoName: "體積",
        optionTwoId: "3c2057d4-499d-47a5-bc87-942b9da88c21",
        optionTwo: "大",
        currentStock: 11,
        totalStockOut: 22,
        filterStockOut: 33,
      },
      {
        productId: "3b41dc30-f5b1-4e07-95e8-71359fb23916",
        productName: "商品名稱1",
        specifyOneId: "6b41dc30-f5b1-4e07-95e8-71359fb23916",
        specifyOneName: "顏色",
        optionOneId: "ec128628-6193-4c13-9c07-26a247b06c13",
        optionOne: "黑",
        specifyTwoId: "38fcbc9e-0ad3-4d9c-bffe-b7e37719d8a2",
        specifyTwoName: "體積",
        optionTwoId: "3c2057d4-499d-47a5-bc87-942b9da88c2c",
        optionTwo: "小",
        currentStock: 111,
        totalStockOut: 222,
        filterStockOut: 333,
      },
      {
        productId: "3b41dc30-f5b1-4e07-95e8-71359fb23916",
        productName: "商品名稱1",
        specifyOneId: "6b41dc30-f5b1-4e07-95e8-71359fb23916",
        specifyOneName: "顏色",
        optionOneId: "ec128628-6193-4c13-9c07-26a247b06c13",
        optionOne: "黑",
        specifyTwoId: "38fcbc9e-0ad3-4d9c-bffe-b7e37719d8a2",
        specifyTwoName: "體積",
        optionTwoId: "3c2057d4-499d-47a5-bc87-942b9da88c21",
        optionTwo: "大",
        currentStock: 1111,
        totalStockOut: 2222,
        filterStockOut: 3333,
      },
      {
        productId: "3b41dc30-f5b1-4e07-95e8-71359fb23916",
        productName: "商品名稱2",
        currentStock: 11111,
        totalStockOut: 22222,
        filterStockOut: 33333,
      },
    ],
  },
  // 庫存管理頁面 - 現有庫存資料 (下拉選單使用)
  EXISTING_STOCK_LIST: [
    {
      productName: "多功能工具箱",
      productId: "3b41dc30-f5b1-4e07-95e8-71359fb23916",
      counts: 666,
    },
    {
      productName: "產品名稱2",
      productId: "4b41dc30-f5b1-4e07-95e8-71359fb23916",
      specifyData: [
        {
          specifyName: "規格1",
          id: "5b41dc30-f5b1-4e07-95e8-71359fb23916",
          counts: 130,
        },
        {
          specifyName: "規格2",
          id: "6b41dc30-f5b1-4e07-95e8-71359fb23916",
          counts: 18,
        },
        {
          specifyName: "規格3",
          id: "4a41dc30-f5b1-4e07-95e8-71359fb23916",
          counts: 210,
        },
        {
          specifyName: "規格4",
          id: "2a41dc30-f5b1-4e07-95e8-71359fb23916",
          counts: 38,
        },
      ],
    },
    {
      productName: "產品名稱3",
      productId: "3b41dc30-f5b1-4e07-95e8-71359fb23943",
      specifyData: [
        {
          specifyName: "規格1",
          id: "5b41dc30-f5b1-4e07-95e8-71359fb23916",
          counts: 2000,
        },
      ],
    },
  ],
  // 庫存管理頁面 - 現有庫存資料 (下拉選單使用)
  STOCK_RECORD_LIST: [
    {
      historyUuid: "4b41dc30-f5b1-4e07-95e8-71359fb23912",
      type: 0,
      date: "2024/03/17 17:43",
      productId: "3b41dc30-f5b1-4e07-95e8-71359fb23916",
      productName: "多功能工具箱",
      specifyId: "6b41dc30-f5b1-4e07-95e8-71359fb23916",
      specifyName: "顏色",
      quantity: 1000,
      stockQuantity: 1200,
    },
  ],
};
